import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';

import Modal from '../Modal';
import ReactLoading from "react-loading";

const base = 'cashier-pickup-order-card';

@inject(
    'DispatchStore',
    'LiveDispatchStore',
    'ProductionStore',
    'UserStore'
)
@observer
class OrderCards extends Component {
    state = {
        hours: '--',
        minutes: '--',
        seconds: '--',
        delay: false,
        showConfirmationModal: false,
        loading: false
    };

    constructor(props) {
        super(props);
    }

    componentDidMount = () => {
        this.setCountdown();
    };

    setCountdown = () => {
        const { pickup } = this.props;
        const startTime = moment(pickup.start_date, 'x');
        let now = moment();
        let delay = false;

        if (now.isAfter(moment(startTime, 'x'))) {
            delay = true;
        }

        this.setState({ delay }, () => {
            this.interval = setInterval(this.countdown, 1000);
        });
    };

    countdown = () => {
        const { delay } = this.state;
        const { pickup } = this.props;
        const startTime = moment(pickup.start_date, 'x');
        let now, then;

        if (delay) {
            now = moment(startTime, 'x');
            then = moment();
        } else {
            now = moment();
            then = moment(startTime, 'x');
        }

        const duration = moment.duration(then.diff(now)).asMilliseconds();

        const hours = moment.utc(duration).format('H');
        const minutes = moment.utc(duration).format('mm');
        const seconds = moment.utc(duration).format('ss');

        if (!delay && minutes === '00' && seconds === '00') {
            this.setState({ hours, minutes, seconds, delay: true }, () => {
                // audio.loop = true;
                // audio.play();
            });
        } else {
            this.setState({ hours, minutes, seconds });
        }
    };

    resetCountdown = () => {
        if (this.interval) {
            clearInterval(this.interval);
            this.setState(
                {
                    hours: '00',
                    minutes: '00',
                    seconds: '00',
                    delay: false,
                },
                () => {
                    audio.loop = false;
                    audio.pause();
                    audio.currentTime = 0;
                }
            );
        }
    };

    componentWillUnmount() {
        this.resetCountdown();
    }

    handleClickPickup = () => {
        this.setState({ showConfirmationModal: true });
    };

    handleConfirmPickup = () => {
        const { LiveDispatchStore, pickup } = this.props;
        this.setState({ loading: true })
        LiveDispatchStore.handleConfirmPickupOrder(pickup.delivery_id);
        this.setState({ showConfirmationModal: false });
    };

    handleCloseConfirmationModal = () => {
        this.setState({ loading: false })
        this.setState({ showConfirmationModal: false });
    };

    render() {
        const {
            showConfirmationModal,
            hours,
            minutes,
            seconds,
            delay,
        } = this.state;
        const { pickup } = this.props;
        return (
            <Fragment>
                <div
                    className={`${base} ${base}__pickup-order pm bg--white text--left display--flex flex-column justify--space-between`}
                    onClick={this.handleClickPickup}>
                    {this.state.loading ?
                        <ReactLoading
                            type="spin"
                            color="black"
                            height={'10%'}
                            width={'10%'}
                            className="loader"
                        /> :
                        <Fragment>
                            <p className="fs--small text--gray">{pickup.order_number}</p>
                            <p className="fs--medium-medium-large">{pickup.customer_name ? pickup.customer_name : "Walk-in Customer"}</p>
                            <p className={`${pickup.amount_due} ? "fs--medium text--red" : ""`}>RM {(pickup.amount_due * 1.06).toFixed(2)} Due</p>
                            <br />
                            <div className="fs-small display--flex justify--space-between">
                                <p>Pick-up</p>
                                <p className={`order-time ${delay? "text--red blinking": ""}`}>{`${parseInt(hours) > 0 ? `${hours}:` : ``}${minutes}:${seconds}`}</p>
                            </div>
                        </Fragment>}
                </div>
                {showConfirmationModal && (
                    <Modal width="500px">
                        <Fragment>
                            <h1 className="mt0 mbm">
                                Confirm{' '}
                                <span className="text--red">
                                    {pickup.amount_due
                                        ? "RM " + (pickup.amount_due * 1.06).toFixed(2)
                                        : ''}
                                </span>
                                {`${pickup.amount_due ? ' Paid' : ' Pick Up'
                                    }`}
                            </h1>
                            <button
                                className="btn btn-black display--block width--100 mbs"
                                onClick={() => this.handleConfirmPickup()}
                            >
                                Confirm
                        </button>
                            <button
                                className="btn btn-borderless display--block width--100"
                                onClick={this.handleCloseConfirmationModal}
                            >
                                Cancel
                        </button>
                            {pickup.amount_due ?
                                <p className={`${base}__cash-reminder`}>Staff will be responsible for any missing cash and payments.</p>
                                : ""}
                        </Fragment>
                    </Modal>
                )}
            </Fragment>
        )
    }
}



export default OrderCards;