import React, { Component, Fragment } from 'react';
import InventoryHeader from './InventoryHeader';
import UnitSkuStockInput from './UnitSkuStockInput';
import Loading from '../Loading';
import Modal from '../Modal';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { action, computed } from 'mobx';
import InventoryConstants from './InventoryConstants';
import ComponentStockStore from "../../stores/ComponentStockStore";

const base = 'sku-entry';

@inject('ComponentStockStore')
@observer
class InventoryComponentDailyCheckUpdate extends Component {
    initialState = {
        openSkuList: false,
        isResubmit: false,
        showConfirmationModel: false,
        stockQuantity: {},
        errorMessage: '',
        currentSku: '',
        stockQuantityPerPackaging: {}
    };

    state = this.initialState;

    componentDidMount = () => {
        const {
            match: {
                params: { shift },
            },
            ComponentStockStore: {
                handleGetUnitBatchesForDailyStockCheck,
                handleGetUnitSkus,
            }
        } = this.props;

        handleGetUnitBatchesForDailyStockCheck();
        handleGetUnitSkus();
        this.setState({ shift: shift });
    };

    getInitialSku = () => {
        const {
            ComponentStockStore: {
                unitSkuList
            }
        } = this.props;

        for (const sku of Object.keys(unitSkuList).sort()) {
            const batch = unitSkuList[sku];
            if (!batch[InventoryConstants.FINAL_CHECK_COMPLETED]) return sku;
        }
        return Object.keys(unitSkuList).sort()[0];
    }

    handleOpenSkuList = () => {
        this.setState({ openSkuList: true });
    };

    handleCloseSkuList = () => {
        this.setState({ openSkuList: false });
    };

    handleCloseConfirmationModal = () => {
        this.setState({ showConfirmationModel: false });
    };

    handleSetCurrentSku = (sku, batch) => {
        this.setState({ currentSku: sku, quantity: batch.unsold + batch.pre_order_quantity, openSkuList: false });
    };

    handleChangeStockInputs = (sku, quantity, quantityPerPackaging) => {
        const { stockQuantity, stockQuantityPerPackaging } = this.state;
        const updatedStockQuantity = {
            ...stockQuantity,
            [sku]: quantity,
        };
        const updatedStockQuantityPerPackaging = {
            ...stockQuantityPerPackaging,
            [sku]: quantityPerPackaging,
        };
        this.setState({ stockQuantity: updatedStockQuantity, stockQuantityPerPackaging: updatedStockQuantityPerPackaging});
    };

    handleSubmit = () => {
        const { stockQuantity, isResubmit } = this.state;
        let { currentSku } = this.state;

        if (!currentSku) {
            currentSku = this.getInitialSku();
        }
        if (this.isSkuChecked(currentSku) && !isResubmit) {
            this.setState({ isResubmit: true });
        } else {
            let pathname = window.location.href.split('/');
            if (pathname[pathname.length -1] === "initial"){
                alert('Please use final stock check');
                return;
            }

            const { ComponentStockStore: { unitSkuList } } = this.props;
            const skuList = unitSkuList;
            let errorMessage = Object.entries(stockQuantity).reduce(
                (acc, [unitSku, quantity]) => {
                    const batch = skuList[unitSku];
                    const batchDates = `null - null`;
                    if (
                        quantity &&
                        Math.abs(quantity - batch.virtual_stock) > 0
                    ) {
                        acc.push({
                            unitSku,
                            count: quantity,
                            virtualStock: batch.virtual_stock,
                            batchDates
                        });
                    }
                    return acc;
                },
                []
            );

            if (errorMessage.length) {
                this.setState({ showConfirmationModel: true, errorMessage });
            } else {
                this.handleSendRequest();
            }
        }
    };

    handleSendRequest = async () => {
        const { stockQuantity } = this.state;
        const {
            ComponentStockStore: { handleBulkUpdateUnitBatch },
        } = this.props;

        for (const sku of Object.keys(stockQuantity)) {
            if (stockQuantity[sku] > 10000){
                alert('The quantities added for ' + sku + ' do not seem right. Please alert the tech team if this is not the case.');
                return;
            }
        }

        let requests = Object.keys(stockQuantity)
            .map((sku) => ({
                unitSku: sku,
                quantity: stockQuantity[sku]
            }));
        await handleBulkUpdateUnitBatch(requests);
        this.setState(this.initialState);
    };

    isSkuChecked = (currentSku) => {
        const {
            ComponentStockStore: {
                unitSkuList
            }
        } = this.props;

        if (!unitSkuList) {
            return false;
        }
        const batch = unitSkuList[currentSku];
        return batch[InventoryConstants.FINAL_CHECK_COMPLETED];
    }

    getTotalSkuChecked = () => {
        const {
            ComponentStockStore: {
                unitSkuList
            }
        } = this.props;
        if (!unitSkuList) {
            return 0;
        }

        return Object.keys(unitSkuList).reduce(
            (total, sku) => (this.isSkuChecked(sku) ? total + 1 : total),
            0
        );
    }

    render() {
        const {
            openSkuList,
            stockQuantity,
            stockQuantityPerPackaging,
            isResubmit,
            showConfirmationModel,
            errorMessage
        } = this.state;
        let {
            currentSku
        } = this.state;
        const {
            ComponentStockStore: {
                componentStockUpdateLoading,
                unitSkuList
            },
        } = this.props;
        if (!currentSku && unitSkuList) {
            currentSku = this.getInitialSku();
        }
        if (componentStockUpdateLoading || !currentSku) {
            return <Loading />;
        }

        const totalSkus = Object.keys(unitSkuList).length;
        const totalSkusChecked = this.getTotalSkuChecked();
        const isChecked = this.isSkuChecked(currentSku);
        const hasInput = Object.keys(stockQuantity).includes(currentSku);

        return (
            <div className="mbxl pbxl">
                <InventoryHeader title="Daily SKU Check" />
                <div
                    className={`${base}__progress fs--medium-medium-large text--bolder ps mbs`}
                >
                    <span
                        className={`text--red`}
                    >{`${totalSkusChecked} out of ${totalSkus} `}</span>
                    <span>SKUs completed </span>
                    <button
                        className="btn width--50 mtm"
                        onClick={this.handleOpenSkuList}
                    >
                        Jump to SKU
                    </button>
                </div>
                <div className={`pl`}>
                    <p className={`fs--large text--bolder mrs`}>
                        {currentSku + ' : ' + unitSkuList[currentSku].name}
                    </p>
                    <p className={`fs--medium`}></p>
                </div>
                <UnitSkuStockInput
                    key={currentSku}
                    batch={unitSkuList[currentSku]}
                    isResubmit={isResubmit}
                    onChange={this.handleChangeStockInputs}
                    value={stockQuantity[currentSku] || ''}
                    quantityPerPackaging={stockQuantityPerPackaging[currentSku] || ''}
                />
                {openSkuList && (
                    <div className={`production__drawer-mask`}></div>
                )}
                <div
                    className={`production__drawer ${
                        openSkuList ? 'open' : ''
                    }`}
                >
                    <div
                        className={`production__nav-btn drawer-close`}
                        onClick={this.handleCloseSkuList}
                    >
                        <svg className="icon">
                            <use xlinkHref={`#icon-cross`} />
                        </svg>
                    </div>
                    <p className="fs--medium-large text--sofiapro text--left mbm">
                        Select SKU
                    </p>
                    <div className={`production__drawer-buttons`}>
                        {Object.keys(unitSkuList).sort().map((unitSku) => (
                            <Fragment key={unitSku}>
                                <div className={`production__drawer-button ${
                                        this.isSkuChecked(unitSku)
                                            ? 'selected'
                                            : 'unchecked'
                                    } ${
                                        currentSku === unitSku ? 'current' : ''
                                    } font-small`}
                                    onClick={() =>
                                        this.handleSetCurrentSku(unitSku, unitSkuList[unitSku])
                                    }
                                >
                                    {unitSku + ' : ' + unitSkuList[unitSku].name}
                                </div>
                            </Fragment>
                        ))}
                    </div>
                </div>
                <div className="footer-bottom">
                    <button
                        className={`btn width--75 ${
                            isChecked && !isResubmit ? 'btn-selected' : ''
                        }`}
                        onClick={this.handleSubmit}
                        disabled={!hasInput && (!isChecked || isResubmit)}
                    >
                        {isChecked && !isResubmit
                            ? 'Do Again'
                            : 'Submit and Next'}
                    </button>
                </div>
                {showConfirmationModel && (
                    <Modal>
                        <h3>
                            Attention! Big difference in count, are you sure
                            your count is correct?
                        </h3>
                        {errorMessage.map(
                            ({ unitSku, count, virtualStock, batchDates }) => (
                                <div className="mbm" key={unitSku}>
                                    <h3>{unitSku}</h3>
                                    <div
                                        className={`display--flex justify--space-between flex-align-items-center`}
                                    >
                                        <div>
                                            <div className="modal-content">{`Virtual Stock = ${virtualStock} meals`}</div>
                                            <div className="modal-content">{`Your count = ${count} meals`}</div>
                                        </div>
                                        {count === '0' ? (
                                            <div className="width--50">
                                                You are removing stock that has
                                                already been sold. CS will be
                                                notified to cancel the orders.
                                                This will impact your HUB
                                                PERFORMANCE KPI. Are you sure?
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            )
                        )}
                        <div className="display--flex justify--space-around">
                            <button
                                className="btn ms"
                                onClick={this.handleCloseConfirmationModal}
                            >
                                Count again
                            </button>
                            <button
                                className="btn btn-primary ms"
                                onClick={this.handleSendRequest}
                            >
                                Yes, save count
                            </button>
                        </div>
                    </Modal>
                )}
            </div>
        );
    }
}

export default InventoryComponentDailyCheckUpdate;
