import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Fullscreen from 'react-full-screen';
import {Helmet} from "react-helmet";

import Loading from '../Loading';
import OrderCard from './OrderCard';

import { getFromLocalStorage } from '../../services/utils';
import WarmerCard from '../warmer/WarmerCard';

const base = 'orders-kitchen';
const ONE_SECOND = 1000;

@inject('OrdersKitchenStore', 'WarmerStore', 'UserStore')
@observer
class OrdersKitchen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFullscreen: false,
        };
    }

    componentDidMount() {
        const {
            OrdersKitchenStore: {
                handleGetDineInOrders,
                handleGetDeliveryOrders,
            },
            UserStore,
        } = this.props;
        const {
            selectedHub: { isOnDemandProduction },
        } = UserStore;
        if (isOnDemandProduction) {
            handleGetDineInOrders(true);
            handleGetDeliveryOrders(true);
            this.interval = setInterval(() => {
                handleGetDineInOrders(false);
                handleGetDeliveryOrders(false);
            }, 5 * ONE_SECOND); // 5 seconds
        }

        //fetching warmer items
        const {
            WarmerStore: { getWarmerLevel },
        } = this.props;
        let hubCode = getFromLocalStorage('hub').value;
        getWarmerLevel(hubCode);
        this.interval = setInterval(() => {
            getWarmerLevel(hubCode);
        }, 10 * ONE_SECOND);
    }

    render() {
        const { OrdersKitchenStore, WarmerStore, UserStore } = this.props;
        const {
            isCurrentPageFullScreen: isFullscreen,
            handleSetFullscreen,
            selectedHub: { isOnDemandProduction },
        } = UserStore;
        const {
            delieveryOrders,
            dineInOrders,
            delieveryOrdersLoading,
            dineInOrdersLoading,
        } = OrdersKitchenStore;
        const { warmerItems } = WarmerStore;

        const allOrders = [...dineInOrders, ...delieveryOrders]
            .filter((order) => order.status === 'Pending')
            .sort((a, b) => a.order_date - b.order_date);

        return (
            <Fullscreen
                enabled={isFullscreen}
                onChange={(isFullscreen) => handleSetFullscreen(isFullscreen)}
            >
                <Helmet>
                    <title>Orders - Kitchen</title>
                </Helmet>
                {isOnDemandProduction && (
                    <div className={`${base} width--100`}>
                        {dineInOrdersLoading || delieveryOrdersLoading ? (
                            <Loading />
                        ) : (
                            <div
                                className={`${base}__orders ${
                                    isFullscreen && 'full-screen'
                                }`}
                            >
                                {warmerItems && warmerItems.length > 0 && (
                                    <WarmerCard items={warmerItems} />
                                )}
                                {allOrders &&
                                    allOrders.length > 0 &&
                                    allOrders.map((order) => (
                                        <OrderCard
                                            order={order}
                                            key={order.order_number}
                                        />
                                    ))}
                            </div>
                        )}
                    </div>
                )}
            </Fullscreen>
        );
    }
}

export default OrdersKitchen;
