import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import LoginComponent from '../library/LoginComponent';

@inject('UserStore')
@observer
class Login extends Component {
    state = {};

    componentDidMount() {
        const { UserStore } = this.props;
        UserStore.handleGetHubs();
    }

    handleChange = (value, name) => {
        this.setState((state) => ({
            [name]: value,
        }));
    };

    login = (e) => {
        const { UserStore } = this.props;
        e.preventDefault();
        let formData = new FormData();
        formData.append('user_type', 'Employee');
        for (const key of Object.keys(this.state)) {
            formData.append(key, this.state[key]);
        }
        UserStore.login(formData);
    };

    render() {
        const { UserStore } = this.props;

        return (
            <LoginComponent
                loading={UserStore.loading}
                title="Hub Production - Pop Outlets"
                handleChange={this.handleChange}
                value={this.state.value}
                login={this.login}
            />
        );
    }
}

export default Login;
