import { action, observable } from 'mobx';
import * as api from '../services/api';
import UserStore from './common/UserStore';
import moment from 'moment';

class OrdersKitchenStore {
    @observable delieveryOrders = [];
    @observable dineInOrders = [];
    @observable delieveryOrdersLoading = true;
    @observable dineInOrdersLoading = true;
    deliveryOrdersLastModified = null;
    dineInOrdersLastModified = null;

    @observable date = moment().format('DD-MM-YYYY');

    @action handleGetDeliveryOrders = (
        forceLatest = false,
        date = this.date
    ) => {
        let ifModifiedSince = null;
        if (!forceLatest) {
            ifModifiedSince = this.deliveryOrdersLastModified;
        }
        api.getDeliveryOrders(date, ifModifiedSince)
            .then((res) => {
                if (res.modified) {
                    const { lastModified, responseBody } = res;
                    this.deliveryOrdersLastModified = lastModified;
                    if (!responseBody.list) {
                        //there's no delivery order
                        this.delieveryOrders = [];
                    }
                    //this array will be used to filter out completed orders from prepared/Done orders list
                    if (responseBody.list && responseBody.list.length > 0) {
                        this.delieveryOrders =
                            responseBody.list.filter(
                                (order) => order.status === 'Pending'
                            ) || [];
                    }
                } else {
                    // no change since the last call
                }
            })
            .catch((err) => {
                console.log(err);
                UserStore.message = err.message;
            })
            .finally(() => {
                this.delieveryOrdersLoading = false;
            });
    };

    @action handleGetDineInOrders = (forceLatest = false, date = this.date) => {
        this.date = date;
        let ifModifiedSince = null;
        if (!forceLatest) {
            ifModifiedSince = this.dineInOrdersLastModified;
        }

        api.getDineInOrders(this.date, ifModifiedSince)
            .then((res) => {
                if (res.modified) {
                    const { lastModified, responseBody } = res;
                    const { list: dineInOrders, message } = responseBody;
                    this.dineInOrdersLastModified = lastModified;
                    const newDineInOrders = dineInOrders
                        ? dineInOrders.filter(
                              (order) =>
                                  order.status === 'Pending' &&
                                  order.items &&
                                  order.items.length > 0
                          )
                        : [];

                    const sortedDineInOrders =
                        newDineInOrders &&
                        newDineInOrders.sort(
                            (a, b) => a.order_date - b.order_date
                        );

                    this.dineInOrders = sortedDineInOrders
                        ? [...sortedDineInOrders]
                        : [];
                }
            })
            .catch((err) => {
                UserStore.message = err.message;
            })
            .finally(() => {
                this.dineInOrdersLoading = false;
            });
    };
}

const store = new OrdersKitchenStore();
export default store;
