import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';

import CycleSKU from './CycleSKU';
import SalePlatformIcon from "./SalePlatformIcon";

const base = 'orders-kitchen';
const FIVE_MINUTES_IN_MILLISECONDS = 5 * 60 * 1000;
const FOUR_SECONDS_IN_MILLISECONDS = 5 * 1000;

@inject('ProductionStore')
@observer
class OrderCard extends Component {
    state = {
        hours: '--',
        minutes: '--',
        seconds: '--',
        delay: false,
        isNewOrder: false,
    };

    componentDidMount = () => {
        const { order } = this.props;
        this.audio = new Audio(`${process.env.PUBLIC_URL}/bell_reduced_volume.mp3`);

        const now = moment(order.order_date, 'x');
        const then = moment();

        const duration = moment.duration(then.diff(now)).asMilliseconds();

        if (duration <= FOUR_SECONDS_IN_MILLISECONDS && !this.state.isNewOrder) {
            this.audio.play();
            this.setState({ isNewOrder: true });
        }
        if (duration > FOUR_SECONDS_IN_MILLISECONDS && this.state.isNewOrder) {
            this.audio.pause();
            this.setState({ isNewOrder: false });
        }
        if (duration > FIVE_MINUTES_IN_MILLISECONDS) {
            this.setState({ delay: true });
        }

        this.setCountdown();
    };

    componentDidUpdate = (prevProps) => {
        const { order } = this.props;
        if (order.order_date !== prevProps.order.order_date) {
            this.resetCountdown();
            this.setCountdown();
        }
    };

    componentWillUnmount() {
        this.resetCountdown();
    }

    resetCountdown = () => {
        if (this.interval) clearInterval(this.interval);
        this.setState({
            hours: '--',
            minutes: '--',
            seconds: '--',
            delay: false,
        });
    };

    setCountdown = () => {
        this.interval = setInterval(this.countdown, 1000);
    };

    countdown = () => {
        const { order } = this.props;
        const now = moment(order.order_date, 'x');
        const then = moment();

        const duration = moment.duration(then.diff(now)).asMilliseconds();

        const hours = moment.utc(duration).format('H');
        const minutes = moment.utc(duration).format('mm');
        const seconds = moment.utc(duration).format('ss');

        if (duration < 0) {
            // future order.
            this.setState({
                hours,
                minutes,
                seconds,
                isNewOrder: false,
            });
        } else {
            if (duration <= FOUR_SECONDS_IN_MILLISECONDS) {
                this.audio.play();
                this.setState({ hours, minutes, seconds, isNewOrder: true });
            } else {
                if (duration > FIVE_MINUTES_IN_MILLISECONDS) {
                    this.setState({ hours, minutes, seconds, delay: true });
                } else {
                    this.audio.pause();
                    this.setState({
                        hours,
                        minutes,
                        seconds,
                        isNewOrder: false,
                    });
                }
            }
        }
    };

    render() {
        const { order } = this.props;
        const {
            hours,
            minutes,
            seconds,
            delay: isOrderDelay,
            isNewOrder,
        } = this.state;
        const customerName = order.customer_name || '';
        const customerNameToShow =
            customerName.length > 9
                ? customerName.slice(0, 7) + '..'
                : customerName;
        const referenceNumber = order.table_number != null ? order.table_number : order.pick_up_number;
        let orderNumberToShow = order.pick_up_type
            ? referenceNumber
            : order.bag_number;
        if (order.sale_platform === 'GrabFood'
            || order.sale_platform === 'FoodPanda'
            || order.sale_platform === 'ShopeeFood') {
            orderNumberToShow = null;
        }
        const orderType = !order.pick_up_type
            ? 'DELIVERY'
            : order.pick_up_type === 'TAKE_AWAY'
            ? 'TAKE_AWAY'
            : 'DINE_IN';

        return (
            <div
                className={`${base}__order_card ${base}__order_card_${
                    orderType === 'DELIVERY'
                        ? 'DELIVERY'.toLowerCase()
                        : orderType === 'TAKE_AWAY'
                        ? 'TAKE_AWAY'.toLowerCase()
                        : 'DINE_IN'.toLowerCase()
                }  ${isOrderDelay && 'delay'}`}
            >
                <div
                    className={`${base}__card_header  ${
                        isNewOrder &&
                        `new-order-${
                            orderType === 'DELIVERY'
                                ? 'black-white'
                                : 'black-yellow'
                        }`
                    }  order_header_type_${
                        orderType === 'DELIVERY'
                            ? 'DELIVERY'.toLowerCase()
                            : orderType === 'TAKE_AWAY'
                            ? 'TAKE_AWAY'.toLowerCase()
                            : 'DINE_IN'.toLowerCase()
                    } ${isOrderDelay && 'delay'}`}
                >
                    <div className={`${base}__icon_num_name`}>
                        <SalePlatformIcon
                            key={order.order_number}
                            order={order}
                            isOrderDelay = {isOrderDelay}
                            isNewOrder = {isNewOrder}
                        />

                        <div
                            className={`${base}__order_number_customer_name ${
                                isNewOrder &&
                                `new-order-${
                                    orderType === 'DELIVERY'
                                        ? 'black-white'
                                        : 'black-yellow'
                                }`
                            }  ${isOrderDelay && 'delay'}`}
                        >
                            {orderNumberToShow || ''} {customerNameToShow}
                        </div>
                    </div>

                    <p
                        className={`${base}__time_elapse ${
                            isNewOrder &&
                            `new-order-${
                                orderType === 'DELIVERY'
                                    ? 'black-white'
                                    : 'black-yellow'
                            }`
                        }  ${isOrderDelay && 'delay'}`}
                    >
                        {`${
                            parseInt(hours) > 0 ? `${hours}:` : ``
                        }${minutes}:${seconds}`}
                    </p>
                </div>
                <div
                    className={`${base}__skus_container sku_container_${
                        orderType === 'DELIVERY'
                            ? 'DELIVERY'.toLowerCase()
                            : orderType === 'TAKE_AWAY'
                            ? 'TAKE_AWAY'.toLowerCase()
                            : 'DINE_IN'.toLowerCase()
                    } sku_container_${isOrderDelay && 'delay'}`}
                >
                    {order.items &&
                        order.items.length > 0 &&
                        order.items.map((item) => (
                            <CycleSKU
                                item={item}
                                key={`${item.sku}_${item.incremental_number}`}
                            />
                        ))}
                </div>
            </div>
        );
    }
}

export default OrderCard;
