import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { toast, cssTransition } from 'react-toastify';

import 'animate.css/animate.min.css';
import 'react-toastify/dist/ReactToastify.css';

import Modal from '../Modal';
import SKUItem from '../common/SKUItem';
import PrintStickersModal from './PrintStickersModal';
import CustomToastContainer from './CustomToastContainer';

const base = 'live-dispatch';

@inject('LiveDispatchStore')
@observer
class Bag extends Component {
    state = {
        showConfirmationModal: false,
        isPrintQRButtonDisabled: false,
        isPrintBagButtonDisabled: false,
    };

    handleModalState = (state) => {
        this.setState({ showConfirmationModal: state });
    };

    sendToastNotification = () => {
        const { bag } = this.props;
        const slide = cssTransition({
            enter: 'animate__animated animate__slideInDown',
            exit: 'animate__animated animate__slideOutUp',
        });
        //send toast
        toast.configure();
        toast(<CustomToastContainer />, {
            toastId: bag.order_number,
            transition: slide,
            closeButton: this.closeButton,
            pauseOnHover: false,
            pauseOnFocusLoss: false,
            closeOnClick: false,
        });
    };

    handleClickPrint = (e) => {
        e.preventDefault();
        this.handleModalState(true);
    };

    handlePrintBtnState = (btnName, btnState, invertState) => {
        this.setState({ [btnName]: btnState });
        if (invertState) {
            setTimeout(() => {
                this.setState({ [btnName]: !btnState });
            }, 5 * 1000);
        }
    };

    closeButton = () => {
        return (
            <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => toast.dismiss()}
            >
                <rect
                    x="1.06055"
                    width="19.7"
                    height="1.5"
                    transform="rotate(45 1.06055 0)"
                    fill="white"
                />

                <rect
                    width="19.7"
                    height="1.5"
                    transform="matrix(-0.707107 0.707107 0.707107 0.707107 13.9302 0)"
                    fill="white"
                />
            </svg>
        );
    };
    render() {
        const { bag, LiveDispatchStore } = this.props;
        const { CANCELLED, IN_WARMER, ON_ROUTE, ROLLING_HYBRID, ALWAYS_AVAILABLE } =
            LiveDispatchStore;
        const {
            showConfirmationModal,
            isPrintQRButtonDisabled,
            isPrintBagButtonDisabled,
        } = this.state;
        const onRoute = bag.items
            .filter((item) => item.stock_type === ROLLING_HYBRID || item.stock_type === ALWAYS_AVAILABLE)
            .every((item) => item.status === ON_ROUTE);

        return (
            <Fragment>
                <div className={`${base}__bag`}>
                    <div className={`${base}__bag-header display--flex`}>
                        <div className='display--flex'>
                            <div className={`${base}__bag-info`}>
                                <svg className="icon-bag">
                                    <use
                                        xlinkHref={`${
                                            onRoute ? '#icon-bag-purple' : '#icon-bag'
                                        }`}
                                    />
                                </svg>
                                {bag.status === CANCELLED && (
                                    <svg className="icon-cancel">
                                        <use xlinkHref={`#icon-cancel`} />
                                    </svg>
                                )}
                                <span className={`${base}__bag-no`}>
                                    {bag.bag_number}
                                </span>
                            </div>                        
                            {bag.cutlery_count > 0 && (
                                <div className='text--white pls'>
                                    <svg className="icon-cutlery">
                                        <use xlinkHref={`#icon-cutlery`} />
                                    </svg>
                                    <span className={`${base}__cutlery`}>
                                        {bag.cutlery_count}
                                    </span>
                                </div>
                            )}
                        </div>
                        <button
                            className={`${base}__print btn bg--white`}
                            onClick={(e) => this.handleClickPrint(e)}
                        >
                            <svg className="icon-print-medium">
                                <use xlinkHref={`#icon-print-medium`} />
                            </svg>
                        </button>
                    </div>
                    <div className={`${base}__skus_container`}>
                        {bag.items.map((item, index) => (
                            <SKUItem item={item} key={`${item.sku + index}`} />
                        ))}
                    </div>
                </div>

                {showConfirmationModal && (
                    <Modal
                        customStyles={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        width={'45%'}
                        height={'56%'}
                        padding="0px"
                        borderRadius="0px"
                    >
                        <PrintStickersModal
                            orderOrBag={bag}
                            handleModalState={this.handleModalState}
                            sendToastNotification={this.sendToastNotification}
                            isPrintQRButtonDisabled={isPrintQRButtonDisabled}
                            isPrintBagButtonDisabled={isPrintBagButtonDisabled}
                            handlePrintBtnState={this.handlePrintBtnState}
                        />
                    </Modal>
                )}
            </Fragment>
        );
    }
}

export default Bag;
