import React, { Component, Fragment } from 'react';
import { WhiteLogoWhiteLeaf, WhitePopLogo } from '../components/BrandLogo';
import Loading from '../components/Loading';
import CountrySelector from './CountrySelectorComponent';
import { inject, observer } from 'mobx-react';
import Select from 'react-select';
import { getCity } from '../services/cities';

const base = 'login';

@inject('UserStore')
@observer
class LoginComponent extends Component {
    state = { selectedCity: '' };

    componentDidMount() {
        if (getCity()) {
            this.setState({ selectedCity: getCity() });
        }
    }

    handleSelectCity = (city, cb) => {
        this.props.UserStore.selectedHub = '';
        this.setState({ selectedCity: city });
        cb(city);
    };
    renderHubSelector = () => {
        const { UserStore } = this.props;
        const hubsInCurrentCity = UserStore.hubsList.filter(
            (hub) => hub.city === this.state.selectedCity
        );

        return (
            <div className={`${base}__hub-selector-container`}>
                <Select
                    value={UserStore.selectedHub}
                    autoFocus
                    required
                    options={hubsInCurrentCity}
                    style={{ width: '523px', marginBottom: '10px' }}
                    name="hubs"
                    onChange={UserStore.setSelectedHub}
                    placeholder="Select hub"
                />
            </div>
        );
    };

    render() {
        const { UserStore } = this.props;
        const isLoading = this.props.loading;
        const title = this.props.title;

        return (
            <div className={`${base}`}>
                {isLoading && <Loading />}
                <div className="container display--flex flex-column justify--center">
                    <div className="section-area text--left">
                        {/* <WhiteLogoWhiteLeaf /> */}
                        <WhitePopLogo />
                        <div className="display--flex">
                            <p
                                className="text--white"
                                style={{ marginBottom: 0 }}
                            >
                                {title}
                            </p>
                            <div className={`margin--left dropdown`}>
                                <CountrySelector
                                    render={(city, cities, changeCity) => (
                                        <Fragment>
                                            <div className="display--flex ">
                                                <p
                                                    className="text--white"
                                                    style={{
                                                        marginRight: '10px',
                                                    }}
                                                >
                                                    {city === 'Kuala_Lumpur'
                                                        ? 'Kuala Lumpur'
                                                        : 'Bangkok'}
                                                </p>
                                                <button
                                                    className="bg--none"
                                                    style={{
                                                        padding: '12px 0',
                                                    }}
                                                >
                                                    <svg
                                                        className="iconmoon-icon center--vertical"
                                                        style={{
                                                            fill: 'white',
                                                        }}
                                                    >
                                                        <use xlinkHref="#icon-chevron-thin-down" />
                                                    </svg>
                                                </button>
                                            </div>
                                            <div className="list--none dropdown-content">
                                                {Object.keys(cities).map(
                                                    (city) => (
                                                        <a
                                                            key={city}
                                                            className="cursor-pointer"
                                                            onClick={() =>
                                                                this.handleSelectCity(
                                                                    city,
                                                                    changeCity
                                                                )
                                                            }
                                                        >
                                                            <p>
                                                                {cities[city]}
                                                            </p>
                                                        </a>
                                                    )
                                                )}
                                            </div>
                                        </Fragment>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    {UserStore.hubsList.length > 0 && this.renderHubSelector()}
                    <form onSubmit={this.props.login}>
                        <div className="section-area display--flex flex-column">
                            <input
                                className="border--rounded border--solid"
                                style={{ marginBottom: '20px' }}
                                required
                                name="username"
                                type="email"
                                value={this.props.value}
                                onChange={(e) =>
                                    this.props.handleChange(
                                        e.target.value,
                                        e.target.name
                                    )
                                }
                                placeholder="Username"
                            ></input>
                            <input
                                className="border--rounded border--solid"
                                required
                                id="password"
                                name="password"
                                type="password"
                                value={this.props.value}
                                onChange={(e) =>
                                    this.props.handleChange(
                                        e.target.value,
                                        e.target.name
                                    )
                                }
                                placeholder="Password"
                            ></input>
                        </div>
                        <div className="text--left section-area">
                            <button
                                className="text--white border--rounded"
                                disabled={!UserStore.selectedHub}
                            >
                                Log In
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default LoginComponent;
