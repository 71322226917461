import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import DineInOrderRow from './DineInOrderRow';
import Loading from '../Loading';

const base = 'live-dispatch';

@inject('LiveDispatchStore')
@observer
export default class DineInOrders extends Component {
    componentDidMount() {
        const {
            LiveDispatchStore: { date, handleGetDineInOrders },
        } = this.props;
        handleGetDineInOrders(true);
        this.interval = setInterval(() => {
            handleGetDineInOrders(false);
        }, 1000 * 5 * 1); // 5 seconds
    }

    render() {
        const { LiveDispatchStore } = this.props;
        const { dineInOrders, loading } = LiveDispatchStore;

        if (loading) {
            return <Loading />;
        }

        return (
            <div className={`${base}__dine-in-container`}>
                <div className={`${base}__dine-in-headline`}>
                    <h1 className={`display--inline-block ${base}__heading`}>
                        Dine-in, Takeaway & Aggregators
                    </h1>
                    <span className="items-length">{dineInOrders.length}</span>
                </div>
                <div className={`${base}__dine-in-table display--flex flex-wrap`}>
                    {dineInOrders.map((dineInOrder) => (
                        <DineInOrderRow
                            key={dineInOrder.order_number}
                            dineInOrder={dineInOrder}
                        />
                    ))}                    
                </div>
            </div>
        );
    }
}
