import React, { Component } from 'react';
import MealImagesSection from './MealImagesSection';

const base = 'dispatch_scan';
class TakeawayScannerView extends Component {
    render() {
        const {
            scannedItem,
            scannedItemSKU,
            originalItemImageLink,
            itemImage: itemWebCamImage,
        } = this.props;

        return (
            <div className={`${base}__takeaway ${base}__scanner_box_view`}>
                <div className={`${base}__scanner_box_header_row`}>
                    <div className="icon_order_type">
                        <img
                            className={`${base}__icon`}
                            src="https://d2wu471yepgs9e.cloudfront.net/takeaway_icon.svg"
                            alt="take away icon"
                        />
                        <p className={`${base}__order_type_text `}>TAKEAWAY</p>
                    </div>
                    <p className={`${base}__bag_number `}>
                        {scannedItem.bag_number}
                    </p>
                    <p className={`${base}__sku_text`}>{scannedItemSKU}</p>
                </div>
                <MealImagesSection
                    originalItemImageLink={originalItemImageLink}
                    itemWebCamImage={itemWebCamImage}
                />
            </div>
        );
    }
}

export default TakeawayScannerView;
