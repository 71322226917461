import React from 'react';

const base = 'orders-kitchen';

export default function CycleSKU({ item }) {
    return (
        <div className={`${base}__sku_item  ${item.status.toLowerCase()}`}>
            <p className="sku">
                {item.sku}
            </p>

            {item.status === 'ON_ROUTE' && (
                <svg
                    width="50"
                    height="36"
                    viewBox="0 0 50 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="checkmark-svg"
                >
                    <path
                        d="M0.292725 16.0822L19.2702 35.0597L23.2477 31.0822L4.2702 12.1047L0.292725 16.0822Z"
                        fill="#000000"
                    />
                    <path
                        d="M19.2702 35.0597L49.4977 4.83221L45.5202 0.854736L15.2927 31.0822L19.2702 35.0597Z"
                        fill="#000000"
                    />
                </svg>
            )}
            {item.status === 'IN_WARMER' && <p className="warmer_text">W</p>}
        </div>
    );
}
