import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import moment from 'moment';
import { toast, cssTransition } from 'react-toastify';

import 'animate.css/animate.min.css';
import 'react-toastify/dist/ReactToastify.css';

import Modal from '../Modal';
import SKUItem from '../common/SKUItem';
import PrintStickersModal from './PrintStickersModal';
import CustomToastContainer from './CustomToastContainer';
import SalePlatformIcon from "./SalePlatformIcon";

const base = 'live-dispatch';

@inject('LiveDispatchStore', 'ProductionStore')
@observer
class DineInOrderRow extends Component {
    state = {
        hours: '--',
        minutes: '--',
        seconds: '--',
        delay: false,
        showConfirmationModal: false,
        isPrintQRButtonDisabled: false,
        isPrintBagButtonDisabled: false,
    };

    componentDidMount = () => {
        const { dineInOrder } = this.props;
        const now = moment(dineInOrder.order_date, 'x');
        const then = moment();

        const duration = moment.duration(then.diff(now)).asMilliseconds();
        const FIVE_MINUTES_IN_MILLISECONDS = 5 * 60 * 1000;
        if (duration >= FIVE_MINUTES_IN_MILLISECONDS) {
            this.setState({ delay: true });
        }
        this.setCountdown();
    };

    componentDidUpdate = (prevProps) => {
        const { dineInOrder } = this.props;
        if (dineInOrder.order_date !== prevProps.dineInOrder.order_date) {
            this.resetCountdown();
            this.setCountdown();
        }
    };

    componentWillUnmount() {
        this.resetCountdown();
    }

    resetCountdown = () => {
        if (this.interval) clearInterval(this.interval);
        this.setState({
            hours: '--',
            minutes: '--',
            seconds: '--',
            delay: false,
        });
    };

    setCountdown = () => {
        this.interval = setInterval(this.countdown, 1000);
    };

    countdown = () => {
        const { dineInOrder } = this.props;
        const now = moment(dineInOrder.order_date, 'x');
        const then = moment();

        const duration = moment.duration(then.diff(now)).asMilliseconds();

        const hours = moment.utc(duration).format('H');
        const minutes = moment.utc(duration).format('mm');
        const seconds = moment.utc(duration).format('ss');

        if (
            parseInt(minutes) > 4 &&
            parseInt(seconds) > 0 &&
            !this.state.delay
        ) {
            this.setState({ hours, minutes, seconds, delay: true });
        } else {
            this.setState({ hours, minutes, seconds });
        }
    };

    handlePrintIconClick = () => {
        this.setState({ showConfirmationModal: true });
    };

    handleModalState = (state) => {
        this.setState({ showConfirmationModal: state });
    };

    handlePrintBtnState = (btnName, btnState, invertState) => {
        this.setState({ [btnName]: btnState });
        if (invertState) {
            setTimeout(() => {
                this.setState({ [btnName]: !btnState });
            }, 5 * 1000);
        }
    };

    sendToastNotification = () => {
        const { dineInOrder } = this.props;
        const slide = cssTransition({
            enter: 'animate__animated animate__slideInDown',
            exit: 'animate__animated animate__slideOutUp',
        });
        //send toast
        toast.configure();
        toast(<CustomToastContainer />, {
            toastId: dineInOrder.order_number,
            transition: slide,
            closeButton: this.closeButton,
            pauseOnHover: false,
            pauseOnFocusLoss: false,
            closeOnClick: false,
        });
    };

    closeButton = () => {
        return (
            <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => toast.dismiss()}
            >
                <rect
                    x="1.06055"
                    width="19.7"
                    height="1.5"
                    transform="rotate(45 1.06055 0)"
                    fill="white"
                />

                <rect
                    width="19.7"
                    height="1.5"
                    transform="matrix(-0.707107 0.707107 0.707107 0.707107 13.9302 0)"
                    fill="white"
                />
            </svg>
        );
    };

    render() {
        const {
            showConfirmationModal,
            hours,
            minutes,
            seconds,
            delay,
            isPrintQRButtonDisabled,
            isPrintBagButtonDisabled,
        } = this.state;
        const { dineInOrder } = this.props;
        const customerName = dineInOrder.customer_name || '';
        const customerNameToShow =
            customerName.length > 9
                ? customerName.slice(0, 7) + '..'
                : customerName;

        let isAggregatorPlatform = false;
        if (dineInOrder.sale_platform === 'GrabFood'
            || dineInOrder.sale_platform === 'FoodPanda'
            || dineInOrder.sale_platform === 'ShopeeFood') {
            isAggregatorPlatform = true;
        }

        return (
            <Fragment>
                <div className={`${base}__dine-in-order ${delay ? 'delay' : ''}`}>
                    <div className={`${base}__bag-header display--flex justify--space-between ${delay ? 'delay' : ''}`}>
                        <SalePlatformIcon
                            key={dineInOrder.order_number}
                            dineInOrder={dineInOrder}
                        />
                        <div className="order-info-container">
                            <div className="icon-number-name">                                
                                {isAggregatorPlatform && (
                                    <div className="number-name">
                                        <p className="aggregator_short_order_id">
                                            {customerNameToShow}
                                        </p>
                                    </div>)}
                                {!isAggregatorPlatform && (
                                    <div className="number-name display--flex">
                                        <p className="pick_up_number" style={{maxWidth: "60px", overflow: "hidden"}}>
                                            {dineInOrder.table_number != null ? dineInOrder.table_number : dineInOrder.pick_up_number}
                                        </p>
                                        <p className="pick_up_number plxs">
                                            {customerNameToShow}
                                        </p>
                                    </div>)}
                            </div>
                            {dineInOrder.transaction_status === 'Pending' && (
                                <p className="order-amount-due">
                                    RM {(dineInOrder.amount_due * 1.06).toFixed(2)} due
                                </p>
                            )}
                        </div>

                        <div>
                            <div
                                className={`order-time-box ${delay ? 'delay' : ''}`}
                            >
                                <p className="order-time">
                                    {`${delay ? '-' : ''}`}
                                    {`${
                                        parseInt(hours) > 0 ? `${hours}:` : ``
                                    }${minutes}:${seconds}`}
                                </p>
                                <button
                                    className={`${base}__print btn bg--white`}
                                    onClick={this.handlePrintIconClick}
                                >
                                    <svg className="icon-print-medium">
                                        <use xlinkHref={`#icon-print-medium`} />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>


                    
                    <div className="order-items-skus">
                        {dineInOrder.items.map((item) => (
                            <SKUItem item={item} key={`${item.sku}`} />
                        ))}
                    </div>
                    
                </div>
                {showConfirmationModal && (
                    <Modal
                        customStyles={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        width={'45%'}
                        height={'56%'}
                        padding="0px"
                        borderRadius="0px"
                    >
                        <PrintStickersModal
                            orderOrBag={dineInOrder}
                            handleModalState={this.handleModalState}
                            sendToastNotification={this.sendToastNotification}
                            isPrintQRButtonDisabled={isPrintQRButtonDisabled}
                            isPrintBagButtonDisabled={isPrintBagButtonDisabled}
                            handlePrintBtnState={this.handlePrintBtnState}
                        />
                    </Modal>
                )}
            </Fragment>
        );
    }
}

export default DineInOrderRow;
