import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { Fragment } from 'react';
import Modal from '../Modal';
import ReactLoading from 'react-loading';

const base = 'cashier-pickup-order-card';

@inject(
    'DispatchStore',
    'LiveDispatchStore',
    'ProductionStore',
    'UserStore'
)
@observer
class CashierPickupOrderCards extends Component {
    state = {
        hours: '--',
        minutes: '--',
        seconds: '--',
        delay: false,
        showConfirmationModal: false,
        loading: false
    };

    constructor(props) {
        super(props);
    }

    componentDidMount = () => {
        const { dineInOrder } = this.props;
        const now = moment(dineInOrder.order_date, 'x');
        const then = moment();

        const duration = moment.duration(then.diff(now)).asMilliseconds();
        const FIVE_MINUTES_IN_MILLISECONDS = 5 * 60 * 1000;
        if (duration >= FIVE_MINUTES_IN_MILLISECONDS) {
            this.setState({ delay: true });
        }
        this.setCountdown();
    };

    componentDidUpdate = (prevProps) => {
        const { dineInOrder } = this.props;
        if (dineInOrder.order_date !== prevProps.dineInOrder.order_date) {
            this.resetCountdown();
            this.setCountdown();
        }
    };

    setCountdown = () => {
        this.interval = setInterval(this.countdown, 1000);
    };

    countdown = () => {
        const { dineInOrder } = this.props;
        const now = moment(dineInOrder.order_date, 'x');
        const then = moment();

        const duration = moment.duration(then.diff(now)).asMilliseconds();

        const hours = moment.utc(duration).format('H');
        const minutes = moment.utc(duration).format('mm');
        const seconds = moment.utc(duration).format('ss');

        if (
            parseInt(minutes) > 7 &&
            parseInt(seconds) > 0 &&
            !this.state.delay
        ) {
            this.setState({ hours, minutes, seconds, delay: true });
        } else {
            this.setState({ hours, minutes, seconds });
        }
    };

    handleClickComplete = () => {
        const { dineInOrder, LiveDispatchStore } = this.props;
        this.setState({
            loading: true
        })
        if (dineInOrder.invoice_no && dineInOrder.invoice_no === 'terminal') {
            this.setState({ showConfirmationModal: true });
        } else {
            LiveDispatchStore.handleConfirmDineInOrder(dineInOrder.delivery_id);
        }
    };

    handleCloseConfirmationModal = () => {
        this.setState({ loading: false })
        this.setState({ showConfirmationModal: false });
    };

    handleConfirmationSubmit = () => {
        this.setState({ loading: true })
        const { dineInOrder, LiveDispatchStore } = this.props;
        LiveDispatchStore.handleConfirmDineInOrder(dineInOrder.delivery_id);
        this.setState({ showConfirmationModal: false });
    };

    render() {
        const {
            showConfirmationModal,
            hours,
            minutes,
            seconds,
            delay
        } = this.state;
        const { dineInOrder} = this.props;

        return (
            <Fragment>
                <div
                    className={`${base} pm bg--white text--left display--flex flex-column justify--space-between`}
                    onClick={this.handleClickComplete}>

                    {this.state.loading ?
                        <ReactLoading
                        type="spin"
                        color="black"
                        height={'10%'}
                        width={'10%'}
                        className="loader"
                    /> :
                        <Fragment>
                            <p className="fs--small text--gray">{dineInOrder.pick_up_number}</p>
                            <p className="fs--medium-medium-large">{dineInOrder.customer_name}</p>

                            {dineInOrder.transaction_status === "Pending" ? <p className="fs--medium text--red">RM {(dineInOrder.amount_due * 1.06).toFixed(2)} Due</p> : <p>RM 0.00 Due</p>}
                            <br />
                            <div className="fs-small display--flex justify--space-between">
                                {dineInOrder.pick_up_type === "TAKE_AWAY" ? <span>Take-away</span> : <span>Dine-in</span>}
                                <span className={`order-time ${delay && 'text--red blinking'}`}>{`${parseInt(hours) > 0 ? `${hours}:` : ``
                                    }${minutes}:${seconds}`}</span>
                            </div>
                        </Fragment>
                    }
                </div>

                {showConfirmationModal && (
                    <Modal width={'30%'}>
                        <div className={`${base}__modal`}>
                            <h3 className="mbm fs--medium-large">
                                Confirm Payment Received
                            </h3>
                            <p>
                                Did the customer pay?
                            </p>
                            <div className="display--flex flex-column justify--center flex-align-items-center">
                                <button
                                    className="btn btn-black width--100 ms"
                                    onClick={() =>
                                        this.handleConfirmationSubmit()
                                    }
                                >
                                    Confirm
                                </button>

                                <p
                                    className="cursor-pointer text--gray text--center width--100 ms fs--small-medium "
                                    onClick={this.handleCloseConfirmationModal}
                                >
                                    Cancel
                                </p>
                            </div>
                            <p className={`${base}__cash-reminder`}>Staff will be responsible for any missing cash and payments.</p>
                        </div>
                    </Modal>
                )}
            </Fragment>
        )
    }
}


export default CashierPickupOrderCards;