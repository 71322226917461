import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import {Helmet} from "react-helmet";

import Loading from '../Loading';
import CashierPickupOrderCard from './CashierPickupOrderCard';
import CashierScheduledPickupOrderCard from './CashierScheduledPickupOrderCard';

const base = 'cashier-pickup';

@inject('DispatchStore', 'LiveDispatchStore', 'ProductionStore', 'UserStore')
@observer
class Pickup extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const {
            LiveDispatchStore: { handleGetCashierScreenResponse },
        } = this.props;
        handleGetCashierScreenResponse(true);
        this.interval = setInterval(() => {
            handleGetCashierScreenResponse(false);
        }, 1000 * 5); // 5 seconds
    }

    componentWillUnmount() {
        const {
            LiveDispatchStore: { resetProductionLastModified },
        } = this.props;

        clearInterval(this.interval);
        resetProductionLastModified();
    }

    render() {
        const { LiveDispatchStore } = this.props;
        const { inStoreOrders, loading, pickUpOrders } = LiveDispatchStore;

        if (loading) {
            return <Loading />;
        }

        return (
            <div>
                <Helmet>
                    <title>Cashier Pickup</title>
                </Helmet>
                <div className={`${base} width--100 height--100`}>
                    <div className={`height--100 display--flex`}>
                        <div className={`width--75`}>
                            <div className="current-production__column-block header">
                                <p className="m0 mls">Orders</p>
                            </div>
                            <div
                                className={`${base}__dine-in display--flex plm`}
                            >
                                {inStoreOrders.map((inStoreOrder) => (
                                    <CashierPickupOrderCard
                                        key={inStoreOrder.order_number}
                                        dineInOrder={inStoreOrder}
                                    />
                                ))}
                            </div>
                        </div>
                        <div className={'width--25'}>
                            <div className="current-production__column-block header">
                                <p className="m0 mls">Scheduled Pickups</p>
                            </div>
                            <div
                                className={`${base}__pickup-container display--flex`}
                            >
                                {pickUpOrders
                                    .map((route) => (
                                        <CashierScheduledPickupOrderCard
                                            pickup={route}
                                            key={route.delivery_id}
                                        />
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Pickup;
