import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import 'animate.css/animate.min.css';
import 'react-toastify/dist/ReactToastify.css';


@observer
class SalePlatformIcon extends Component {
    render() {
        const { dineInOrder } = this.props;
        let salePlatform = dineInOrder.sale_platform;
        if (salePlatform !== 'GrabFood'
            && salePlatform!== 'FoodPanda'
            && salePlatform !== 'ShopeeFood'){
            salePlatform = 'PopMeals'
        }

        return (
            <Fragment>
                {salePlatform === 'GrabFood' && (
                    <img
                        className="take_away_icon"
                        src="https://d2wu471yepgs9e.cloudfront.net/Grab_Logo+1.svg"
                        alt="grabfood icon"
                    />)}
                {salePlatform === 'FoodPanda' && (
                    <img
                        className="take_away_icon"
                        src="https://d2wu471yepgs9e.cloudfront.net/FoodPanda.svg"
                        alt="foodpanda icon"
                    />)}
                {salePlatform === 'ShopeeFood' && (
                    <img
                        className="take_away_icon"
                        src="https://d2wu471yepgs9e.cloudfront.net/Shopee_logo+1.svg"
                        alt="shopeefood icon"
                    />)}
                {salePlatform === 'PopMeals' && (
                    dineInOrder.pick_up_type === 'TAKE_AWAY' ? (
                            <img
                                className="take_away_icon"
                                src="https://d2wu471yepgs9e.cloudfront.net/takeaway_icon.svg"
                                alt="take away icon"
                                style={{filter:"invert(1)"}}
                            />
                        ) : (
                            <img
                                className="dine_in_icon"
                                src={
                                    'https://d2wu471yepgs9e.cloudfront.net/dish-fork-and-knife+1.svg'
                                }
                                alt="dine-in icon"
                                style={{filter:"invert(1)"}}
                            />
                        ))}
            </Fragment>
        );
    }
}

export default SalePlatformIcon;
