import { observable, action, computed, toJS } from 'mobx';
import * as api from '../services/api';
import UserStore from './common/UserStore';
import { saveToLocalStorage, getFromLocalStorage } from '../services/utils';
import moment from 'moment';
import ScanningStore from './common/ScanningStore';
import {getCashierScreenResponse} from "../services/api";

class LiveDispatchStore {
    @observable date = moment().format('DD-MM-YYYY');
    @observable loading = true;
    @observable routes = [];
    @observable dineInOrders = [];
    @observable pickUpOrders = [];
    @observable inStoreOrders = [];
    @observable bags = [];

    //variables for scanning barcode
    @observable scannedItem = null;
    @observable scannedItemSKU = '';
    @observable scannedItemError = '';
    @observable showScannedItemOnLiveDispatch = false;
    @observable itemScanLoading = false;

    //variables for image upload functionality
    @observable isImageUploading = false;
    @observable showImage = false;
    @observable itemImage = null;
    @observable originalItemImageLink = null;

    @observable remakeLoading = false;
    @observable SKURemakeErrorMsg = '';
    @observable closedFoodPandaStores = [];
    @observable showFoodPandaCloseStoreModal = false;
    @observable recentlyClosedFoodPandaStores = [];

    routesLastModified = null;

    // bag status
    CANCELLED = 'Cancelled';

    // item status
    ON_ROUTE = 'ON_ROUTE';
    IN_WARMER = 'IN_WARMER';
    IN_PRODUCTION = 'IN_PRODUCTION';

    @action resetProductionLastModified = () => {
        this.routesLastModified = null;
    };

    @action setItemImage = (image) => {
        if (image) {
            this.itemImage = image;
        }
    };

    errorCallbackRemakeAPI = (errorObject) => {
        if (errorObject.response) {
            errorObject.response.json().then((body) => {
                if (body.message) {
                    this.SKURemakeErrorMsg = body.message;
                }
            });
        }
    };
    errorCallbackSKUScanAPI = (errorObject) => {
        if (errorObject.response) {
            errorObject.response.json().then((body) => {
                if (body.message) {
                    this.scannedItemError = body.message;
                }
            });
        }
    };

    @action handleUploadImage = (image, item) => {
        this.isImageUploading = true;
        api.uploadItemImage(image, item)
            .then((res) => {
                this.showImage = true;
                setTimeout(() => {
                    this.showImage = false;
                }, 5000);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                this.isImageUploading = false;
            });
    };

    @action handleRemakeSKUItem = (item, callback) => {
        this.remakeLoading = true;
        let status;
        api.handleRemakeSKUItem(item, this.errorCallbackRemakeAPI)
            .then((res) => {
                status = 'SUCCESS';
            })
            .catch((err) => {
                console.log(err);
                status = 'FAILED';
            })
            .finally(() => {
                this.remakeLoading = false;
                callback && callback(status);
            });
    };

    @action handledScannedItem = (
        todayMenuId,
        incrementalNumber,
        pickUpNumber = null
    ) => {
        this.showScannedItemOnLiveDispatch = true;
        this.itemScanLoading = true;

        api.handledScannedItem(
            todayMenuId,
            this.date,
            incrementalNumber,
            pickUpNumber,
            this.errorCallbackSKUScanAPI
        )
            .then((res) => {
                this.scannedItem = res;
                this.scannedItemError = '';
                this.originalItemImageLink = res.dispatch_scan_example_image;

                if (this.itemImage) {
                    const imageToBeUploaded = String(
                        this.itemImage.split('base64,')[1]
                    ); //removing the pre-string from the image
                    this.handleUploadImage(
                        imageToBeUploaded,
                        res.item
                    );
                }
            })
            .catch((err) => {
                this.scannedItem = null;
                console.log(err);
            })
            .finally(() => {
                this.itemScanLoading = false;

                setTimeout(() => {
                    this.showScannedItemOnLiveDispatch = false;
                }, 5 * 1000);
            });
    };

    @action handleGetDispatches = (forceLatest = false, date = this.date) => {
        this.date = date;
        let ifModifiedSince = null;
        if (!forceLatest) {
            ifModifiedSince = this.routesLastModified;
        }

        api.getRoutes(this.date, ifModifiedSince)
            .then((res) => {
                if (res.modified) {
                    console.log('res.modified');
                    const { lastModified, responseBody } = res;
                    const { bags, routes } = responseBody;
                    this.routesLastModified = lastModified;

                    const sortedBags =
                        bags &&
                        bags.sort((a, b) => a.bag_number - b.bag_number);
                    this.bags = sortedBags ? [...sortedBags] : [];

                    const pickUpOrders = this.bags
                        .filter((bag) => bag.customer_self_pickup_at_outlet)
                        .map((bag) => {
                            const {
                                amount_due,
                                delivery_id,
                                delivery_time,
                                order_number,
                                payment_method,
                                pre_tax_net_total,
                            } = bag;
                            return {
                                isPickUpOrder: true,
                                amount_due,
                                order_number,
                                delivery_id,
                                start_date: delivery_time,
                                payment_method,
                                pre_tax_net_total,
                                bags: [bag],
                            };
                        });

                    const allOrders = routes.concat(pickUpOrders);

                    const sortedRoutes = allOrders
                        .filter(
                            (route) =>
                                route.bags.length &&
                                !route.bags.every(
                                    (bag) => bag.status === this.CANCELLED
                                )
                        )
                        .sort(
                            (a, b) =>
                                a.start_date - b.start_date ||
                                a.dispatch_no - b.dispatch_no ||
                                a.route_no - b.route_no
                        );
                    this.routes = sortedRoutes ? [...sortedRoutes] : [];
                }
                this.loading = false;
            })
            .catch((err) => {
                UserStore.message = err.message;
            });
    };

    @action handleGetDineInOrders = (forceLatest = false, date = this.date) => {
        this.date = date;
        let ifModifiedSince = null;
        if (!forceLatest) {
            ifModifiedSince = this.routesLastModified;
        }

        api.getDineInOrders(this.date, ifModifiedSince)
            .then((res) => {
                if (res.modified) {
                    const { lastModified, responseBody } = res;
                    const { list: dineInOrders, message } = responseBody;
                    this.routesLastModified = lastModified;
                    const newDineInOrders = dineInOrders
                        ? dineInOrders.filter(
                              (order) =>
                                  order.status !== 'Completed' &&
                                  order.items &&
                                  order.items.length > 0
                          )
                        : [];

                    const sortedDineInOrders =
                        newDineInOrders &&
                        newDineInOrders.sort(
                            (a, b) => a.order_date - b.order_date
                        );

                    this.dineInOrders = sortedDineInOrders
                        ? [...sortedDineInOrders]
                        : [];
                }
                this.loading = false;
            })
            .catch((err) => {
                UserStore.message = err.message;
            });
    };

    @action handleGetFoodPandaStoreStatus = () => {
        this.recentlyClosedFoodPandaStores = [];
        api.getFoodPandaStoreStatus().then((response) => {
            response.forEach((entry) => {
                if (entry.open === false && this.closedFoodPandaStores.indexOf(entry.sale_platform_name) === -1){
                    this.closedFoodPandaStores.push(entry.sale_platform_name)
                    this.recentlyClosedFoodPandaStores.push(entry.sale_platform_name);
                }
                if (entry.open === true && this.closedFoodPandaStores.indexOf(entry.sale_platform_name) > -1){
                    this.closedFoodPandaStores.splice(this.closedFoodPandaStores.indexOf(entry.sale_platform_name), 1);
                }
            })
        })
            .catch((err) => console.log(err))
    }


    @action handleGetCashierScreenResponse = (forceLatest = false, date = this.date) => {
        this.date = date;
        let ifModifiedSince = null;
        if (!forceLatest) {
            ifModifiedSince = this.routesLastModified;
        }

        api.getCashierScreenResponse(this.date, ifModifiedSince)
            .then((res) => {
                if (res.modified) {
                    const { lastModified, responseBody } = res;
                    const { pick_up_orders, in_store_orders } = responseBody;

                    this.routesLastModified = lastModified;

                    const sortedBags =
                        pick_up_orders &&
                        pick_up_orders.sort((a, b) => a.bag_number - b.bag_number);
                    this.pickUpOrders = sortedBags ? [...sortedBags] : [];

                    this.pickUpOrders = this.pickUpOrders
                        .map((bag) => {
                            const {
                                amount_due,
                                delivery_id,
                                delivery_time,
                                order_number,
                                payment_method,
                                pre_tax_net_total,
                            } = bag;
                            return {
                                amount_due,
                                order_number,
                                delivery_id,
                                start_date: delivery_time,
                                payment_method,
                                pre_tax_net_total,
                                bags: [bag],
                            };
                        });
                    const newInStoreOrders = in_store_orders
                        ? in_store_orders.filter(
                            (order) =>
                                order.status !== 'Completed' &&
                                order.items &&
                                order.items.length > 0
                        )
                        : [];

                    const sortedInStoreOrders =
                        newInStoreOrders &&
                        newInStoreOrders.sort(
                            (a, b) => a.order_date - b.order_date
                        );

                    this.inStoreOrders = sortedInStoreOrders
                        ? [...sortedInStoreOrders]
                        : [];
                }
                this.loading = false;
            })
            .catch((err) => {
                UserStore.message = err.message;
            });
    };

    @action handleConfirmPickupOrder = (deliveryId) => {
        api.confirmPickupOrder(deliveryId)
            .catch((err) => console.log(err));
    };

    @action handleConfirmDineInOrder = (deliveryId) => {
        api.confirmDineInOrder(deliveryId)
            .catch((err) => console.log(err));
    };
}

const store = new LiveDispatchStore();
export default store;
