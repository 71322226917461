import React, { Component, Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Header from './components/Header';
import Modal from './components/Modal';
import HubPerformance from './components/performance/HubPerformance';
// import Overview from './components/production/Overview';
import QueueScreen from './components/queueScreen/QueueScreen';
import LiveDispatch from './components/liveDispatch/LiveDispatch';
import PickMode from './components/pickMode/PickMode';
import Warmer from './components/warmer/Warmer';
import DispatchScan from './components/dispatch-scan/DispatchScan';
import Inventory from './components/inventory/Inventory';
import LiveInventoryAdjustment from './components/inventory/LiveInventoryAdjustment';
import LiveInventory from './components/inventory/LiveInventory';
import InventoryAdjustment from './components/inventory/InventoryAdjustment';
import InventoryAdjustmentUpdate from './components/inventory/InventoryAdjustmentUpdate';
import InventoryDailyCheck from './components/inventory/InventoryDailyCheck';
import InventoryDailyCheckShiftMeals from './components/inventory/InventoryDailyCheckShiftMeals';
import InventoryDailyCheckUpdate from './components/inventory/InventoryDailyCheckUpdate';
import InventoryReceiveStock from './components/inventory/InventoryReceiveStock';
import InventoryReceiveStockUpdate from './components/inventory/InventoryReceiveStockUpdate';
import InventoryReceiveStockUpdateAddons from './components/inventory/InventoryReceiveStockUpdateAddons';
import CashierPickup from './components/cashierPickup/CashierPickup';
import OrdersKitchen from './components/ordersKitchen/OrdersKitchen';
import Login from './components/Login';
import { inject, observer } from 'mobx-react';
import SVGSymbols from './components/SVGIcons';
import Cookies from 'js-cookie';
import qs from 'qs';
import InventoryDailyCheckShiftAddons from './components/inventory/InventoryDailyCheckShiftAddons';
import InventoryComponentStockUpdate from './components/inventory/InventoryComponentStockUpdate';
import InventoryAddComponentStock from './components/inventory/InventoryAddComponentStock';
import LiveInventoryComponent from './components/inventory/LiveInventoryComponent';
import InventoryDailyCheckShiftComponents from './components/inventory/InventoryDailyCheckShiftComponents';
import InventoryComponentDailyCheckUpdate from './components/inventory/InventoryComponentDailyCheckUpdate';

@inject('UserStore')
@observer
class App extends Component {
    state = {
        loading: true,
    };

    componentDidMount() {
        const { UserStore } = this.props;
        const windowUrl = window.location.href;
        const parameters = windowUrl ? qs.parse(windowUrl.split('?')[1]) : {};
        const { token, hub: hubCode } = parameters;
        if (token && hubCode) {
            UserStore.authedUser = token;
        } else {
            if (Cookies.get('token')) {
                UserStore.authedUser = Cookies.get('token');
            }
        }

        this.setState({ loading: false });

        UserStore.checkPrinterStatus();
    }

    closeModal = () => {
        const {
            UserStore: { setMessage },
        } = this.props;
        setMessage('');
    };

    render() {
        const {
            UserStore: { authedUser, message, currentPage },
        } = this.props;
        if (this.state.loading) {
            return <div />;
        }

        return (
            <div className={`App ${currentPage}__page`}>
                <Router>
                    <Fragment>
                        <SVGSymbols />
                        {authedUser && <Header />}
                        {message && (
                            <Modal onBackdropClicked={this.closeModal}>
                                {message}
                            </Modal>
                        )}
                        {!authedUser ? (
                            <Route exact path="/" component={Login} />
                        ) : (
                            <Switch>
                                <Route exact path={'/'} component={LiveDispatch} />
                                <Route
                                    exact
                                    path={'/production'}
                                >
                                    <Redirect to="/orders-kitchen" />
                                </Route>

                                <Route
                                    exact
                                    path={'/cashier-pickup'}
                                    component={CashierPickup}
                                />
                                <Route
                                    exact
                                    path={'/orders-kitchen'}
                                    component={OrdersKitchen}
                                />
                                <Route
                                    exact
                                    path="/hub-performance"
                                    component={HubPerformance}
                                />
                                <Route
                                    exact
                                    path="/live-dispatch"
                                    component={LiveDispatch}
                                />
                                <Route
                                    exact
                                    path="/dispatch-scan"
                                    component={DispatchScan}
                                />
                                <Route
                                    exact
                                    path="/inventory"
                                    component={Inventory}
                                />
                                <Route
                                    exact
                                    path="/inventory/live/adjustment"
                                    component={LiveInventoryAdjustment}
                                />
                                <Route
                                    exact
                                    path="/inventory/live"
                                    component={LiveInventory}
                                />
                                <Route
                                    exact
                                    path="/inventory/live/adjustment/component"
                                    component={LiveInventoryComponent}
                                />
                                <Route
                                    exact
                                    path="/inventory/adjustment"
                                    component={InventoryAdjustment}
                                />
                                <Route
                                    exact
                                    path="/inventory/adjustment/:type(addons|meals)"
                                    component={InventoryAdjustmentUpdate}
                                />
                                <Route
                                    exact
                                    path="/inventory/adjustment/component"
                                    component={InventoryComponentStockUpdate}
                                />
                                <Route
                                    exact
                                    path="/inventory/daily-check"
                                    component={InventoryDailyCheck}
                                />
                                <Route
                                    exact
                                    path="/inventory/daily-check/meals"
                                    component={InventoryDailyCheckShiftMeals}
                                />
                                <Route
                                    exact
                                    path="/inventory/daily-check/meals/:shift"
                                    component={InventoryDailyCheckUpdate}
                                />
                                <Route
                                    exact
                                    path="/inventory/daily-check/addons"
                                    component={InventoryDailyCheckShiftAddons}
                                />
                                <Route
                                    exact
                                    path="/inventory/daily-check/addons/:shift"
                                    component={InventoryDailyCheckUpdate}
                                />
                                <Route
                                    exact
                                    path="/inventory/daily-check/components"
                                    component={InventoryDailyCheckShiftComponents}
                                />
                                <Route
                                    exact
                                    path="/inventory/daily-check/components/:shift"
                                    component={InventoryComponentDailyCheckUpdate}
                                />
                                <Route
                                    exact
                                    path="/inventory/receive-stock"
                                    component={InventoryReceiveStock}
                                />
                                <Route
                                    exact
                                    path="/inventory/receive-stock/:itemType(addons|meals)"
                                    component={InventoryReceiveStockUpdate}
                                />
                                <Route
                                    exact
                                    path="/inventory/receive-stock/addons-supplier"
                                    component={
                                        InventoryReceiveStockUpdateAddons
                                    }
                                />
                                <Route
                                    exact
                                    path="/inventory/add-component-stock"
                                    component={InventoryAddComponentStock}
                                />
                                <Route
                                    exact
                                    path="/queue-screen"
                                    component={QueueScreen}
                                />
                                <Route
                                    exact
                                    path="/pick-mode"
                                    component={PickMode}
                                />
                                <Route
                                    exact
                                    path="/warmer"
                                    component={Warmer}
                                />
                            </Switch>
                        )}
                    </Fragment>
                </Router>
            </div>
        );
    }
}

export default App;
