import React, { Component } from 'react';
import DispatchScanHeader from './DispatchScanHeader';
import ScanOverview from './ScanOverview';
import Fullscreen from 'react-full-screen';
import { inject, observer } from 'mobx-react';

@inject('DispatchStore', 'ScanningStore')
@observer
class DispatchScan extends Component {
    state = {
        isFullscreen: false,
    };

    handleSetFullscreen = () => {
        this.setState((prevState) => ({
            isFullscreen: !prevState.isFullscreen,
        }));
    };

    handleScanWrapper = (scannedBarCode) => {
        const { ScanningStore, DispatchStore } = this.props;
        const { handleScan } = ScanningStore;
        const { handledScannedItem } = DispatchStore;
        handleScan(scannedBarCode, handledScannedItem);
    };

    render() {
        const { isFullscreen } = this.state;
        const { DispatchStore } = this.props;

        const {
            scannedItem,
            scannedItemSKU,
            scannedItemError,
            scannerLoading,
        } = DispatchStore;

        return (
            <Fullscreen
                enabled={isFullscreen}
                onChange={(isFullscreen) => this.setState({ isFullscreen })}
            >
                <DispatchScanHeader goFullscreen={this.handleSetFullscreen} />
                <ScanOverview
                    scannedItem={scannedItem}
                    scannedItemSKU={scannedItemSKU}
                    scannedItemError={scannedItemError}
                    handleScan={this.handleScanWrapper}
                    loading={scannerLoading}
                />
            </Fullscreen>
        );
    }
}

export default DispatchScan;
