import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import Modal from '../Modal';
import moment from 'moment';

const audio = new Audio(`${process.env.PUBLIC_URL}/alarm.mp3`);

const base = 'live-dispatch';

@inject('LiveDispatchStore')
@observer
class Route extends Component {
    state = {
        hours: '0',
        minutes: '00',
        seconds: '00',
        delay: false,
        orderHours: '0',
        orderMinutes: '00',
        orderSeconds: '00',
        orderDelay: false,
        showConfirmationModal: false,
    };

    componentDidMount = () => {
        this.setCountdown();
    };

    setCountdown = () => {
        const { route } = this.props;
        const startTime = moment(route.start_date, 'x');
        const originalOrderTime = route.original_order_date ? moment(route.original_order_date, 'x') : null;
        let now = moment();
        let delay = false;
        let orderDelay = false;

        if (now.isAfter(moment(startTime, 'x'))) {
            delay = true;
        }

        if (originalOrderTime && now.isAfter(moment(originalOrderTime, 'x'))) {
            orderDelay = true;
        }

        this.setState({ delay, orderDelay }, () => {
            this.interval = setInterval(this.countdown, 1000);
        });
    };

    countdown = () => {
        const { delay, orderDelay } = this.state;
        const { route } = this.props;
        const startTime = moment(route.start_date, 'x');
        const originalOrderTime = moment(route.original_order_date, 'x');
        let now, then, orderNow, orderThen;

        if (delay) {
            now = moment(startTime, 'x');
            then = moment();
        } else {
            now = moment();
            then = moment(startTime, 'x');
        }

        if (orderDelay) {
            orderNow = moment(originalOrderTime, 'x');
            orderThen = moment();
        } else {
            orderNow = moment();
            orderThen = moment(originalOrderTime, 'x');
        }

        const duration = moment.duration(then.diff(now)).asMilliseconds();
        const orderDuration = moment.duration(orderThen.diff(orderNow)).asMilliseconds();

        const hours = moment.utc(duration).format('H');
        const minutes = moment.utc(duration).format('mm');
        const seconds = moment.utc(duration).format('ss');
        const orderHours = moment.utc(orderDuration).format('H');
        const orderMinutes = moment.utc(orderDuration).format('mm');
        const orderSeconds = moment.utc(orderDuration).format('ss');

        if (!delay && minutes === '00' && seconds === '00') {
            this.setState({ hours, minutes, seconds, delay: true });
        } else {
            this.setState({ hours, minutes, seconds });
        }
        if (!orderDelay && orderMinutes === '00' && orderSeconds === '00') {
            this.setState({ orderHours, orderMinutes, orderSeconds, orderDelay: true });
        } else {
            this.setState({ orderHours, orderMinutes, orderSeconds });
        }
    };

    resetCountdown = () => {
        if (this.interval) {
            clearInterval(this.interval);
            this.setState(
                {
                    hours: '00',
                    minutes: '00',
                    seconds: '00',
                    delay: false,
                    orderHours: '00',
                    orderMinutes: '00',
                    orderSeconds: '00',
                    orderDelay: false
                },
                () => {
                    audio.loop = false;
                    audio.pause();
                    audio.currentTime = 0;
                }
            );
        }
    };

    handleClickPickup = () => {
        this.setState({ showConfirmationModal: true });
    };

    handleConfirmPickup = () => {
        const { LiveDispatchStore, route } = this.props;
        LiveDispatchStore.handleConfirmPickupOrder(route.delivery_id);
        this.setState({ showConfirmationModal: false });
    };

    handleCloseConfirmationModal = () => {
        this.setState({ showConfirmationModal: false });
    };

    componentWillUnmount() {
        this.resetCountdown();
    }

    render() {
        const {
            hours,
            minutes,
            seconds,
            delay,
            orderHours,
            orderMinutes,
            orderSeconds,
            orderDelay,
            showConfirmationModal,
        } = this.state;
        const { route, LiveDispatchStore } = this.props;
        const { CANCELLED, ON_ROUTE, ROLLING_HYBRID, ALWAYS_AVAILABLE } = LiveDispatchStore;

        const onRoute = (bag) =>
            bag.items
                .filter((item) => item.stock_type === ROLLING_HYBRID || item.stock_type === ALWAYS_AVAILABLE)
                .every((item) => item.status === ON_ROUTE);
        const allBagsOnRoute = route.bags.every((bag) => onRoute(bag));

        return (
            <Fragment>
                <div
                    className={`${base}__route ${
                        allBagsOnRoute ? 'on_route' : ''
                    } ${delay ? 'delay' : ''} `}
                >
                    {route.isPickUpOrder ? (
                        <Fragment>
                            <div
                                className={` ${base}__route-content-container display--flex width--100 height--100  flex-column`}
                            >
                                <div className={`${base}__route-left-content display--flex`}>
                                    <div className={`${base}__route-number-and-dispatch display--flex`}>
                                        {/* <svg className="icon-customer">
                                            <use xlinkHref={`#icon-customer`} />
                                        </svg> */}
                                        <div className={`${base}__rider width--100`}>
                                            {`#${route.order_number}`}
                                        </div>
                                    </div>
                                </div>
                                
                                <div
                                    className={`${base}__countdown pickup mm`}
                                >
                                    <button
                                        className="btn text--bold"
                                        onClick={this.handleClickPickup}
                                    >
                                        <div className="display--inline-block">
                                            Pickup |{' '}
                                        </div>
                                        <div
                                            className={`${base}__countdown-timer ${
                                                delay ? 'delay' : ''
                                            }`}
                                        >{`${delay ? '-' : ''}${
                                            hours === '0' ? '' : hours + ':'
                                        }${minutes}:${seconds}`}</div>
                                    </button>
                                    <p className="mts mb0 text--center text--thin">
                                        {route.amount_due
                                            ? '💵 Payment Required'
                                            : ''}
                                    </p>
                                </div>
                            </div> 
                            <div className={`display--flex height--100 ps`} style={{ width: "33%", borderLeft: "3px solid #131415"}}>
                                <div
                                    className={`${base}__route-bags ${
                                        delay ? 'delay' : ''
                                    }`}
                                >
                                    {route.bags.map((bag) => (
                                        <div
                                            className={`${base}__bag-info`}
                                            key={bag.order_number}
                                        >
                                            <svg className="icon-bag">
                                                <use
                                                    xlinkHref={`${
                                                        onRoute(bag)
                                                            ? '#icon-bag-purple'
                                                            : '#icon-bag'
                                                    }`}
                                                />
                                            </svg>
                                            {bag.status === CANCELLED && (
                                                <svg className="icon-cancel">
                                                    <use
                                                        xlinkHref={`#icon-cancel`}
                                                    />
                                                </svg>
                                            )}
                                            <span className={`${base}__bag-no`}>
                                                {bag.bag_number}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                           
                        </Fragment>
                    ) : (
                        <Fragment>
                            <div className={`${base}__route-content-container display--flex width--100 height--100`}>
                                <div className={`${base}__route-left-content display--flex`}>
                                    <div className={`${base}__route-number-and-dispatch display--flex`}>
                                        <div className={`${base}__route-dispatch`}>
                                            <div className={`${base}__route-no`}>
                                                {route.route_no}
                                            </div>
                                            <div
                                                className={`${base}__dispatch-no`}
                                            >{`D${route.dispatch_no}`}</div>
                                        </div>
                                        <div className={`${base}__rider`}>
                                            {route.driver_alias}
                                        </div>
                                    </div>
                                    <div className={`${base}__route-timer display--flex justify--space-between`}>
                                        <div className="display--flex flex-column">
                                            <p>Order</p>
                                            {route.original_order_date ? (
                                                <div className={`${base}__countdown`}>
                                                    {`${orderDelay ? '-' : ''}${orderHours === '0' ? '' : orderHours + ':'}${orderMinutes}:${orderSeconds}`}
                                                </div>) : (
                                                <div className={`${base}__countdown`}></div>
                                            )}
                                        </div>
                                        <div className="display--flex flex-column">
                                            <p>Route</p>
                                            <div className={`${base}__countdown`}>{`${
                                                delay ? '-' : ''
                                            }${
                                                hours === '0' ? '' : hours + ':'
                                            }${minutes}:${seconds}`}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`display--flex ps`} style={{ width: "150px", borderLeft: "3px solid #131415"}}>
                                    <div
                                        className={`${base}__route-bags ${
                                            delay ? 'delay' : ''
                                        }`}
                                        >
                                        {route.bags.map((bag) => (
                                            <div
                                                className={`${base}__bag-info`}
                                                key={bag.order_number}
                                            >
                                                <svg className="icon-bag">
                                                    <use
                                                        xlinkHref={`${
                                                            onRoute(bag)
                                                                ? '#icon-bag-purple'
                                                                : '#icon-bag'
                                                        }`}
                                                    />
                                                </svg>
                                                {bag.status === CANCELLED && (
                                                    <svg className="icon-cancel">
                                                        <use
                                                            xlinkHref={`#icon-cancel`}
                                                        />
                                                    </svg>
                                                )}
                                                <span className={`${base}__bag-no`}>
                                                    {bag.bag_number}
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>                            
                        </Fragment>
                    )}
                </div>
                {route.isPickUpOrder && showConfirmationModal && (
                    <Modal width="500px">
                        <Fragment>
                            <h1 className="mt0 mbxs">
                                Confirm{' '}
                                <span className="text--red">
                                    {route.amount_due
                                        ? route.pre_tax_net_total
                                        : ''}
                                </span>
                                {`${
                                    route.amount_due ? ' Collected' : ' Pick Up'
                                }`}
                            </h1>
                            <h1 className="mt0 fs--medium-large">{`#${route.order_number}`}</h1>
                            <p className="text--sofiapro">
                                Do you confirm that the right customer has
                                already paid for their meal and verify their
                                purchase?
                            </p>
                            <button
                                className="btn btn-black display--block width--100 mbs"
                                onClick={() => this.handleConfirmPickup()}
                            >
                                Confirm
                            </button>
                            <button
                                className="btn btn-borderless display--block width--100"
                                onClick={this.handleCloseConfirmationModal}
                            >
                                Cancel
                            </button>
                        </Fragment>
                    </Modal>
                )}
            </Fragment>
        );
    }
}

export default Route;
