import { inject, observer } from 'mobx-react';
import { when } from 'mobx';
import React, { Component, Fragment } from 'react';
import InventoryHeader from './InventoryHeader';
import { withRouter } from 'react-router-dom';

@inject('InventoryStore')
@observer
class LiveInventory extends Component {
    componentDidMount = () => {
        const {
            InventoryStore: {
                handleGetSKUSalesAvailability,
                handleGetProductionBatches,
                getVirtualStockForAllSkusAndBatches,
            },
        } = this.props;

        const itemType = 'Item';
        handleGetSKUSalesAvailability(itemType);
        handleGetProductionBatches();
        when(
            () => this.props.InventoryStore.totalSkus > 0,
            () => getVirtualStockForAllSkusAndBatches()
        );
        this.interval = setInterval(() => {
            handleGetSKUSalesAvailability(itemType);
            handleGetProductionBatches();
            when(
                () => this.props.InventoryStore.loading === false,
                () => getVirtualStockForAllSkusAndBatches()
            );
        }, 1000 * 60 * 1); // 60 seconds
    };

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        const {
            InventoryStore: { skus, itemType },
        } = this.props;

        return (
            <Fragment>
                <InventoryHeader title="Live Inventory Dashboard" />
                <div className="pm" style={{ maxWidth: '600px' }}>
                    <div
                        className={`production__drawer-buttons grid grid-1-1-1-1`}
                    >
                        <h3 className="width--100 text--primary text--left">
                            SKUs
                        </h3>
                        <h3 className="text--bolder">Stock in Outlet</h3>
                        <h3 className="text--bolder">Unsold Stock</h3>
                        <h3 className="text--bolder">Pre-orders</h3>
                        <Fragment>
                            {skus.map((sku) => (
                                <Fragment key={sku.sku}>
                                    <div
                                        key={sku.sku}
                                        className={`sku-button-sm selected} ${
                                            sku.quantity_left < 6 &&
                                            sku.quantity_left > 2
                                                ? 'button-orange'
                                                : sku.quantity_left < 3
                                                ? 'button-red'
                                                : ''
                                        }`}
                                    >
                                        {itemType === 'Item'
                                            ? sku.sku.split('_')[0]
                                            : sku}
                                    </div>
                                    <div className="text--bold fs--medium">{`${sku.virtual_stock}`}</div>
                                    <div className="text--bold fs--medium">{`${sku.quantity_left}`}</div>
                                    <div className="text--bold fs--medium">{`${sku.preorders}`}</div>
                                </Fragment>
                            ))}
                        </Fragment>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default withRouter(LiveInventory);
