import {observer} from "mobx-react";
import React, {Component, Fragment} from "react";

@observer
class SalePlatformIcon extends Component {
    render() {
        const { order, isOrderDelay, isNewOrder } = this.props;
        let salePlatform = order.sale_platform;
        if (salePlatform !== 'GrabFood'
            && salePlatform !== 'FoodPanda'
            && salePlatform !== 'ShopeeFood'){
            salePlatform = 'PopMeals'
        }

        const delievery_icon =
            isOrderDelay || isNewOrder
                ? 'https://d2wu471yepgs9e.cloudfront.net/delievery_icon_white.png'
                : 'https://d2wu471yepgs9e.cloudfront.net/delievery_icon_black.png';
        const takeaway_icon =
            isOrderDelay || isNewOrder
                ? 'https://d2wu471yepgs9e.cloudfront.net/takeaway_icon_white.png'
                : 'https://d2wu471yepgs9e.cloudfront.net/takeaway_icon.svg';
        const dinein_icon =
            isOrderDelay || isNewOrder
                ? 'https://d2wu471yepgs9e.cloudfront.net/dine_in_icon_white.png'
                : 'https://d2wu471yepgs9e.cloudfront.net/dish-fork-and-knife+1.svg';
        const grabfood_icon =
            isOrderDelay || isNewOrder
                ? 'https://d2wu471yepgs9e.cloudfront.net/Grab_Logo+2.svg'
                : 'https://d2wu471yepgs9e.cloudfront.net/Grab_Logo+1.svg';
        const foodpanda_icon =
            isOrderDelay || isNewOrder
                ? 'https://d2wu471yepgs9e.cloudfront.net/FP.svg'
                : 'https://d2wu471yepgs9e.cloudfront.net/FoodPanda.svg';
        const shopeefood_icon =
            isOrderDelay || isNewOrder
                ? 'https://d2wu471yepgs9e.cloudfront.net/Shopee_logo+2.svg'
                : 'https://d2wu471yepgs9e.cloudfront.net/Shopee_logo+1.svg';

        return (
            <Fragment>
                {salePlatform === 'GrabFood' && (
                    <img
                        className="take_away_icon"
                        src={grabfood_icon}
                        alt="grabfood icon"
                    />)}
                {salePlatform === 'FoodPanda' && (
                    <img
                        className="take_away_icon"
                        src={foodpanda_icon}
                        alt="foodpanda icon"
                    />)}
                {salePlatform === 'ShopeeFood' && (
                    <img
                        className="take_away_icon"
                        src={shopeefood_icon}
                        alt="shopeefood icon"
                    />)}
                {salePlatform === 'PopMeals' && (
                    !order.pick_up_type ? (
                        <img
                            className="delievery_icon"
                            src={delievery_icon}
                            alt="delievery icon"
                        />
                        ) : order.pick_up_type === 'TAKE_AWAY' ? (
                        <img
                            className="take_away_icon"
                            src={takeaway_icon}
                            alt="take away icon"
                        />
                        ) : (
                        <img
                            className="dine_in_icon"
                            src={dinein_icon}
                            alt="dine-in icon"
                        />
                    ))}
            </Fragment>
        );
    }
}

export default SalePlatformIcon;