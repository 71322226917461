import React from 'react';
import WarmerSkuCard from './WarmerSkuCard';

const base = 'warmer-screen';

export default function WarmerCard({ items }) {
    return (
        <div className={`${base}__warmer_card`}>
            <div className={`${base}__card_header `}>
                <p className={`${base}__warmer_name`}>Warmer</p>
            </div>
            <div className={`${base}__skus_container`}>
                {items &&
                    items.length > 0 &&
                    items.map((item) => (
                        <WarmerSkuCard
                            key={item.menu_item_id}
                            sku={item.sku}
                            quantity={item.reorder_quantity}
                        />
                    ))}
            </div>
        </div>
    );
}
