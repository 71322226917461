import React, { Component } from 'react';
import MealImagesSection from './MealImagesSection';

const base = 'dispatch_scan';

class DineInScannerView extends Component {
    render() {
        const {
            scannedItem,
            scannedItemSKU,
            originalItemImageLink,
            itemImage: itemWebCamImage,
        } = this.props;

        return (
            <div className={`${base}__dine-in ${base}__scanner_box_view`}>
                <div className={`${base}__scanner_box_header_row`}>
                    <div className="icon_order_type">
                        <img
                            className={`${base}__icon`}
                            src="https://d2wu471yepgs9e.cloudfront.net/dine_in_icon_white.png"
                            alt="dine in icon"
                        />
                        <p className={`${base}__order_type_text text--white`}>
                            DINE-IN
                        </p>
                    </div>

                    <p className={`${base}__bag_number text--white`}>
                        {scannedItem.bag_number}
                    </p>
                    <p className={`${base}__sku_text text--white`}>
                        {scannedItemSKU}
                    </p>
                </div>
                <MealImagesSection
                    originalItemImageLink={originalItemImageLink}
                    itemWebCamImage={itemWebCamImage}
                />
            </div>
        );
    }
}

export default DineInScannerView;
