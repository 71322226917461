import React, { Component, Fragment } from 'react';
import InventoryHeader from './InventoryHeader';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Icon } from '@blueprintjs/core';
import { DateInput } from '@blueprintjs/datetime';
import moment from 'moment';

@inject('StockTransferStoreItems')
@observer
class InventoryReceiveStock extends Component {
    render() {
        const {
            StockTransferStoreItems: { date, handleSetDate },
        } = this.props;

        return (
            <Fragment>
                <InventoryHeader title="Receive New Stock" />
                <div className="secondary-header">
                    <div className="inventory-menu secondary-header__left">
                        <DateInput
                            formatDate={(date) =>
                                moment(date).date() === moment().date()
                                    ? moment(date).format(
                                          '[Today •] DD/MM/YYYY'
                                      )
                                    : moment(date).format('DD/MM/YYYY')
                            }
                            onChange={(date) => handleSetDate(date)}
                            parseDate={(str) =>
                                moment(str, 'DD/MM/YYYY').toDate()
                            }
                            placeholder="Pick a date"
                            value={moment(date, 'DD/MM/YYYY').toDate()}
                            inputProps={{ leftIcon: 'calendar' }}
                            showActionsBar
                        />
                        <h2>
                            {moment(date, 'DD-MM-YYYY').format(
                                'dddd - DD/MM/YYYY'
                            )}
                        </h2>
                        <h2>Choose type of meals</h2>
                        <div className="btn-wrapper mm mtxl">
                            <Link to={`/inventory/receive-stock/meals`}>
                                <button className="btn btn-primary width--300px">
                                    Meals
                                </button>
                            </Link>
                        </div>
                        <div className="btn-wrapper mm">
                            <Link to={`/inventory/receive-stock/addons`}>
                                <button className="btn btn-primary width--300px">
                                    HQ Addons
                                </button>
                            </Link>
                        </div>
                        <div className="btn-wrapper mm">
                            <Link
                                to={`/inventory/receive-stock/addons-supplier`}
                            >
                                <button className="btn btn-primary width--300px">
                                    Other Addons
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default InventoryReceiveStock;
