import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import Modal from '../Modal';
import InventoryHeader from './InventoryHeader';
import { withRouter } from 'react-router-dom';
import Loading from '../Loading';
import moment from 'moment';
import Select from 'react-select';

const base = 'inventory-adjustment';

@inject('ComponentStockStore')
@inject('InventoryStore')
@observer
class InventoryComponentStockUpdate extends Component {
    initialState = {
        showAdjustmentModal: false,
        quantity: 0,
        showConfirmationModal: false,
        showBatchHistoryModal: false,
        currentSku: undefined,
        selectedReason: {},
        delta: 0
    };

    state = this.initialState;

    componentDidMount = () => {
        const {
            ComponentStockStore: {
                handleGetUnitBatches,
                getAllUnitSkus
            },
        } = this.props;
        handleGetUnitBatches();
        getAllUnitSkus();
    };

    handleUnselectSku = () => {
        this.setState({ quantity: 0, currentSku: undefined });
    };

    handleShowAdjustmentModal = () => {
        this.setState({ showAdjustmentModal: true});
    };

    handleCloseAdjustmentModal = () => {
        this.setState(this.initialState);
    };

    handleShowConfirmationModal = () => {
        this.setState({ showConfirmationModal: true });
    };

    handleCloseConfirmationModal = () => {
        this.setState({ showConfirmationModal: false });
    };

    handleShowBatchHistoryModal = () => {
        this.setState({ showBatchHistoryModal: true});
    };

    handleCloseBatchHistoryModal = () => {
        this.setState({ showBatchHistoryModal: false});
    };

    handleSetQuantity = (plusMinus) => {
        const { quantity, delta } = this.state;
        if (plusMinus === 'plus') this.setState({ quantity: quantity + 1, delta: delta + 1 });
        else this.setState({ quantity: quantity - 1, delta: delta - 1 });
    };

    handleSetSKUReason = (reason) => {
        this.setState({selectedReason: reason});
    };

    handleCheckRequest = (showConfirmationModal, noChange) => {
        if (noChange) {
            return;
        }
        if (showConfirmationModal) {
            this.handleShowConfirmationModal();
        } else {
            this.handleSendRequest();
        }
    };

    handleSendRequest = () => {
        const { quantity, currentSku, selectedReason, delta} = this.state;
        const {
            ComponentStockStore: { handleUpdateUnitBatch },
        } = this.props;

        let request = {
            unitSku: currentSku,
            quantity: quantity,
            reason: selectedReason.value,
            delta: delta
        };

        handleUpdateUnitBatch(request);
        this.setState(this.initialState);
    };

    handleSetCurrentSku = (sku, batch, unitBatchesHistory) => {
        let batchUpdateHistory =
            unitBatchesHistory
                .filter(record => record.unit_sku === sku)
                .map(record => ({
                    delta: record.delta,
                    quantity: record.quantity,
                    initiator_username: record.initiator_username,
                    reason: record.reason,
                    updated_at: record.updated_at
                }));
        this.setState({
            currentSku: sku,
            quantity: batch.unsold + batch.pre_order_quantity,
            batchUpdateHistory: batchUpdateHistory
        });
    };

    render() {
        const {
            showAdjustmentModal,
            quantity,
            showConfirmationModal,
            currentSku,
            showBatchHistoryModal
        } = this.state;
        const {
            InventoryStore: {
                componentListForMenuItem,
                reasonsToShowInDropdown,
                selectedReason
            },
            ComponentStockStore: {
                unitBatches,
                componentStockUpdateLoading,
                unitBatchesHistory,
                allUnitSkus
            },
            fromMealUpdateScreen
        } = this.props;
        let unitSkusToUnsold = {};
        for (const batch of unitBatches) {
            if (!unitSkusToUnsold[batch.unit_sku]) {
                unitSkusToUnsold[batch.unit_sku] =
                    {
                        name: batch.unit_name,
                        unsold: batch.quantity,
                        pre_order_quantity: batch.pre_order_quantity
                    };
            } else {
                unitSkusToUnsold[batch.unit_sku] =
                    {
                        name: batch.unit_name,
                        unsold: unitSkusToUnsold[batch.unit_sku].unsold + batch.quantity,
                        pre_order_quantity: unitSkusToUnsold[batch.unit_sku].pre_order_quantity + batch.pre_order_quantity
                    };
            }
        }
        let componentsForMenuItem = {};
        for (let componentForMenuItem of componentListForMenuItem){
            if (unitSkusToUnsold[componentForMenuItem.unit_sku]){
                componentsForMenuItem[componentForMenuItem.unit_sku] = {
                    name : unitSkusToUnsold[componentForMenuItem.unit_sku].name,
                    unsold: unitSkusToUnsold[componentForMenuItem.unit_sku].unsold,
                    pre_order_quantity: unitSkusToUnsold[componentForMenuItem.unit_sku].pre_order_quantity,
                    is_mandatory: componentForMenuItem.mandatory,
                    quantity_per_meal: componentForMenuItem.quantity
                }
            }
        }

        for (const unitSku of allUnitSkus){
            if (unitSkusToUnsold[unitSku.sku]){
                unitSkusToUnsold[unitSku.sku].name = unitSku.name;
            }
        }

        if (componentStockUpdateLoading) {
            return <Loading />
        }

        if (fromMealUpdateScreen === true && componentListForMenuItem.length === 0) {
            return null;
        }

        if (!currentSku && fromMealUpdateScreen && componentListForMenuItem.length > 0){
            return (
                <Fragment>
                    <div className={`secondary-header__wrapper bg--white`} style={{position:'sticky', top: 0}}>
                        <div className={`secondary-header`}>
                            <div className="display--flex justify--center flex-align-items-center pll prl pts pbs">
                                <span className="fs--medium-medium-large">Components</span>
                            </div>
                        </div>
                    </div>
                    <div className='pm'  style={{ maxWidth: '1000px' }}>
                        <div
                            className={`${base}__sku_letter_top_row`}
                        >
                            <h3 className='text--primary text--left fs--medium-large text--bolder'> Component SKU</h3>
                            <h2 className='fs--medium-medium-large text--bolder'>
                                Name
                            </h2>
                            <h2 className='fs--medium-medium-large text--bolder'>
                                Unsold Stock
                            </h2>
                            <h2 className='fs--medium-medium-large text--bolder'>
                                Is Mandatory
                            </h2>
                            <h2 className='fs--medium-medium-large text--bolder'>
                                Quantity Per Meal
                            </h2>
                        </div>
                        <div className={`${base}__skus_of_one_sku_letter`}>
                            {Object.keys(componentsForMenuItem).map((sku) => (
                                <div key={sku} className={`${base}__one_sku_row`}>
                                    <div
                                        className={`${base}__drawer_button selected`}
                                        style={{ flex: '50%' }}
                                        onClick={() => this.handleSetCurrentSku(sku, componentsForMenuItem[sku], unitBatchesHistory)}
                                    >
                                        {sku}
                                    </div>
                                    <div
                                        className='text--bold fs--medium'
                                        style={{ flex: '50%', fontSize: 'small' }}
                                    >{componentsForMenuItem[sku].name}</div>
                                    <div
                                        className='text--bold fs--medium'
                                        style={{ flex: '50%' }}
                                    >{componentsForMenuItem[sku].unsold}</div>
                                    <div
                                        className='text--bold fs--medium'
                                        style={{ flex: '50%' }}
                                    >{componentsForMenuItem[sku].is_mandatory ? 'Yes' : 'No'}</div>

                                    <div
                                        className='text--bold fs--medium'
                                        style={{ flex: '50%' }}
                                    >{componentsForMenuItem[sku].quantity_per_meal}</div>
                                </div>
                            ))}
                    </div>
                    </div>
                </Fragment>
            );
        }

        if (!currentSku) {
            return (
                <Fragment>
                    <InventoryHeader title='Choose SKU to update' />
                    <div className='pm' style={{ maxWidth: '600px' }}>
                        <div
                            className={`${base}__sku_letter_top_row`}
                        >
                            <h3 className='text--primary text--left fs--medium-large text--bolder'>{`SKU`}</h3>
                            <h2 className='fs--medium-medium-large text--bolder'>
                                Name
                            </h2>
                            <h2 className='fs--medium-medium-large text--bolder'>
                                Unsold Stock
                            </h2>
                        </div>
                        <div className={`${base}__skus_of_one_sku_letter`}>
                            {Object.keys(unitSkusToUnsold).map((sku) => (
                                <div key={sku} className={`${base}__one_sku_row`}>
                                    <div
                                        className={`${base}__drawer_button selected`}
                                        style={{ flex: '50%' }}
                                        onClick={() => this.handleSetCurrentSku(sku, unitSkusToUnsold[sku], unitBatchesHistory)}
                                    >
                                        {sku}
                                    </div>
                                    <div
                                        className='text--bold fs--medium'
                                        style={{ flex: '50%', fontSize: 'small' }}
                                    >{unitSkusToUnsold[sku].name}</div>
                                    <div
                                        className='text--bold fs--medium'
                                        style={{ flex: '50%' }}
                                    >{unitSkusToUnsold[sku].unsold}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </Fragment>
            );
        }

        return (
            <Fragment>
                <InventoryHeader
                    title={`${
                        currentSku + ' : ' + unitSkusToUnsold[currentSku].name
                    } Quick SKU Update`}
                    onClickBackLink={this.handleUnselectSku}
                />
                {<Fragment>
                            <div
                                className={`pl row col-lg-12`}
                            >
                                <div className='text--left col-lg-6 col-sm-12 col-xs-12'>
                                    <p className='text--bolder fs--medium-large'>
                                        {currentSku + ' : ' + unitSkusToUnsold[currentSku].name}
                                    </p>
                                    <p className='fs--medium-large'>{`${unitSkusToUnsold[currentSku].unsold} unsold`}</p>
                                    <p className='fs--medium-large'>{`${unitSkusToUnsold[currentSku].unsold + unitSkusToUnsold[currentSku].pre_order_quantity} units in the Hub`}</p>
                                </div>
                                <div className='col-lg-6 text--right col-sm-12 col-xs-12'>
                                    <div className='col-lg-6 col-sm-6 col-xs-6'>
                                        <button
                                            className='btn btn-primary mrm'
                                            onClick={() =>
                                                this.handleShowAdjustmentModal()
                                            }
                                        >
                                            Update
                                        </button>
                                    </div>
                                    <div className='col-lg-6 col-sm-6 col-xs-6'>
                                        <button
                                            className='btn btn-primary mrm'
                                            onClick={() =>
                                                this.handleShowBatchHistoryModal()
                                            }
                                        >
                                            History
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    }
                {showAdjustmentModal && (
                    <Modal>
                        <h3 className='text--boldest fs--medium-medium-large'>
                            {currentSku + ' : ' + unitSkusToUnsold[currentSku].name}
                        </h3>
                        <div className='mbm'>
                            <div>
                                <span>Current Unsold Stock: </span>
                                <span className='text--bolder'>{`${unitSkusToUnsold[currentSku].unsold}`}</span>
                            </div>
                            <div>
                                <span>Units currently in Hub: </span>
                                <span className='text--bolder'>{`${
                                    quantity
                                } Units`}</span>
                            </div>
                        </div>
                        <p className='mbm'>Add or take</p>
                        <div className='quantity-selector'>
                            <button
                                className={`quantity-selector-button`}
                                disabled={
                                    quantity === 0
                                }
                                onClick={() => this.handleSetQuantity('minus')}
                            >
                                <svg className='icon'>
                                    <use xlinkHref={`#icon-minus`} />
                                </svg>
                            </button>
                            <div className='fs--medium-large width--100px text--center'>{`${
                                quantity - unitSkusToUnsold[currentSku].unsold - unitSkusToUnsold[currentSku].pre_order_quantity > 0 ? '+' : ''
                            }${quantity - unitSkusToUnsold[currentSku].unsold - unitSkusToUnsold[currentSku].pre_order_quantity} units`}</div>
                            <button
                                className='quantity-selector-button'
                                onClick={() => this.handleSetQuantity('plus')}
                            >
                                <svg className='icon'>
                                    <use xlinkHref={`#icon-plus`} />
                                </svg>
                            </button>
                        </div>
                        <div className='row'>
                            <div className='col-xs-2'>Reason :</div>
                            <div className='col-xs-10'>
                                <Select
                                    className='fs--medium-medium-large width--50 center--horizontal'
                                    value={selectedReason}
                                    required
                                    options={reasonsToShowInDropdown}
                                    name='reasons'
                                    onChange={this.handleSetSKUReason}
                                    placeholder='Select Reason'
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                />
                            </div>
                        </div>
                        <div className='display--flex justify--space-around'>
                            <button
                                className='btn btn-secondary ms flex-1'
                                onClick={this.handleCloseAdjustmentModal}
                            >
                                Cancel
                            </button>
                            <button
                                className='btn btn-primary ms flex-1'
                                disabled={this.state.selectedReason.value == null}
                                onClick={() => this.handleCheckRequest(quantity < unitSkusToUnsold[currentSku].pre_order_quantity, quantity == unitSkusToUnsold[currentSku].unsold + unitSkusToUnsold[currentSku].pre_order_quantity)}
                            >
                                Confirm
                            </button>
                        </div>
                    </Modal>
                )}
                {showConfirmationModal && (
                    <Modal>
                        <div className='mbm fs--medium'>
                            You are removing stock that has already been sold.
                            CS will be notified to cancel the orders. This will
                            impact your HUB PERFORMANCE KPI. Are you sure?
                        </div>
                        <div className='display--flex justify--space-around'>
                            <button
                                className='btn btn-secondary ms flex-1'
                                onClick={this.handleCloseConfirmationModal}
                            >
                                Go Back
                            </button>
                            <button
                                className='btn btn-primary ms flex-1'
                                onClick={() => this.handleSendRequest()}
                            >
                                Proceed
                            </button>
                        </div>
                    </Modal>
                )}
                {showBatchHistoryModal && (
                    <Modal>
                        <div className="mbm text--center">
                            <div className="history-modal">
                                <span>History</span>
                                <table className="history-table">
                                    <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>New Quantity</th>
                                        <th>Delta</th>
                                        <th>Reason</th>
                                        <th>User</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.batchUpdateHistory.map((history)=>
                                            <tr key ={history.updated_at}>
                                                <td>{moment(history.updated_at, 'x').format('DD/MM/YYYY HH:mm A')}</td>
                                                <td>{history.quantity}</td>
                                                <td>{history.delta}</td>
                                                <td>{history.reason}</td>
                                                <td>{history.initiator_username}</td>
                                            </tr>)}
                                    </tbody>
                                </table>
                            </div>
                            <button
                                className="btn flex-1"
                                onClick={this.handleCloseBatchHistoryModal}
                            >
                                Close
                            </button>
                        </div>
                    </Modal>
                )}
            </Fragment>
        );
    }
}

export default withRouter(InventoryComponentStockUpdate);
