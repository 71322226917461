import { inject, observer } from 'mobx-react';
import React, { Component, Fragment } from 'react';
import InventoryHeader from './InventoryHeader';
import { withRouter } from 'react-router-dom';
import Loading from '../Loading';
import {Table} from "react-bootstrap";

const base = 'inventory-adjustment';


@inject('ComponentStockStore')
@observer
class LiveInventoryComponent extends Component {
    componentDidMount = () => {
        const {
            ComponentStockStore: {
                handleGetLiveInventoryOverview,
            },
        } = this.props;

        handleGetLiveInventoryOverview();
        const SIXTY_SECONDS = 1000 * 60;
        this.interval = setInterval(() => {
            handleGetLiveInventoryOverview();
        }, SIXTY_SECONDS);
    };

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        const {
            ComponentStockStore: { inventoryOverview, inventoryOverviewLoading },
        } = this.props;
        if (inventoryOverviewLoading) {
            return <Loading />;
        }
        let rows = [];
        const INVENTORY_THRESHOLD = 3;
        const sortBySku = (a, b) => {
            if (a.sku < b.sku) {
                return -1;
            } else if (a.sku > b.sku) {
                return 1;
            }
            return 0;
        };

        const sortByMandatory = (a, b) => {
            if (a.mandatory && !b.mandatory) {
                return -1;
            } else if (!a.mandatory && b.mandatory) {
                return 1;
            }
            return 0;
        }

        const getSortedInventoryOverview = (inventoryOverview, sortBySku) => {
            for (const item of inventoryOverview.item_inventory_list) {
                item.component_inventories.replace(item.component_inventories.slice().sort(sortByMandatory));
            }
            return inventoryOverview.item_inventory_list.sort(sortBySku);
        }

        for (const item of getSortedInventoryOverview(inventoryOverview, sortBySku)) {
            let columns = [];
            for (var i = 0; i < 10; i++) {
                columns.push(
                    <td style={{borderLeft: "3px solid gray"}}
                    >
                        {item.component_inventories.length > i ? (item.component_inventories[i].unit_sku + (item.component_inventories[i].mandatory ? '': ' (optional)'))
                            : '-'}
                    </td>
                )
                columns.push(
                    <td style={{fontSize: 'x-small'}}>
                        {item.component_inventories.length > i ? item.component_inventories[i].name : '-'}
                    </td>
                )
                columns.push(
                    <td className={item.component_inventories.length > i
                    && item.component_inventories[i].quantity < INVENTORY_THRESHOLD
                    && item.component_inventories[i].mandatory ? 'bg--red--important': null}
                    >
                        {item.component_inventories.length > i ? item.component_inventories[i].quantity : '-'}
                    </td>
                )
            }
            rows.push(
                    <tr>
                        <td
                        >
                            {item.sku}
                        </td>
                        <td className={item.quantity < INVENTORY_THRESHOLD ? 'bg--red--important': null}
                        >
                            {item.quantity}
                        </td>
                        <td
                        >
                            {item.pre_orders}
                        </td>
                        {columns}
                    </tr>
                )
        }

        return (
            <Fragment>
                <InventoryHeader title='Live Inventory Dashboard (Component)' />
                <Table striped bordered className="fs--medium">
                    <thead>
                        <tr>
                            <th>
                                {`SKU`}
                            </th>
                            <th>
                                In Stock
                            </th>
                            <th>
                                Pre-orders
                            </th>
                            <th>
                                Component 1
                            </th>
                            <th>
                                Name
                            </th>
                            <th>
                                In Stock
                            </th>
                            <th>
                                Component 2
                            </th>
                            <th>
                                Name
                            </th>
                            <th>
                                In Stock
                            </th>
                            <th>
                                Component 3
                            </th>
                            <th>
                                Name
                            </th>
                            <th>
                                In Stock
                            </th>
                            <th>
                                Component 4
                            </th>
                            <th>
                                Name
                            </th>
                            <th>
                                In Stock
                            </th>
                            <th>
                                Component 5
                            </th>
                            <th>
                                Name
                            </th>
                            <th>
                                In Stock
                            </th>
                            <th>
                                Component 6
                            </th>
                            <th>
                                Name
                            </th>
                            <th>
                                In Stock
                            </th>
                            <th>
                                Component 7
                            </th>
                            <th>
                                Name
                            </th>
                            <th>
                                In Stock
                            </th>
                            <th>
                                Component 8
                            </th>
                            <th>
                                Name
                            </th>
                            <th>
                                In Stock
                            </th>
                            <th>
                                Component 9
                            </th>
                            <th>
                                Name
                            </th>
                            <th>
                                In Stock
                            </th>
                            <th>
                                Component 10
                            </th>
                            <th>
                                Name
                            </th>
                            <th>
                                In Stock
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                </Table>
            </Fragment>
        );
    }
}

export default withRouter(LiveInventoryComponent);
