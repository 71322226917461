import React from 'react';
import ScanOverview from './dispatch-scan/ScanOverview';

export default function ScanningBox({
    show,
    scannedItem,
    scannedItemSKU,
    scannedItemError,
    handleScan,
    loading,
    originalItemImageLink,
    itemImage,
}) {
    if (show) {
        return (
            <div
                className="scan-box"
                style={{
                    position: 'absolute',
                    width: '80vw',
                    height: '70vh',
                    left: '10%',
                    top: '10%',
                }}
            >
                <ScanOverview
                    scannedItem={scannedItem}
                    scannedItemSKU={scannedItemSKU}
                    scannedItemError={scannedItemError}
                    handleScan={handleScan}
                    loading={loading}
                    originalItemImageLink={originalItemImageLink}
                    itemImage={itemImage}
                />
            </div>
        );
    } else {
        return <div></div>;
    }
}
