import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { toast, cssTransition } from 'react-toastify';

import Modal from '../Modal';
import 'animate.css/animate.min.css';
import 'react-toastify/dist/ReactToastify.css';

const base = 'sku-item';
const ITEM_STATUS = {
    WARMER: 'IN_WARMER',
    PRODUCTION: 'IN_PRODUCTION',
    ROUTE: 'ON_ROUTE',
};

@inject('LiveDispatchStore', 'UserStore')
@observer
class SKUItem extends Component {
    state = {
        showRemakeModal: false,
        showRemakeFailedModal: false,
    };
    handleRemakeConfirmation = () => {
        const {
            LiveDispatchStore: { handleRemakeSKUItem },
            item,
        } = this.props;
        if (item.order_number) {
            handleRemakeSKUItem(item, this.remakeCallBack);
        } else {
            this.setState({ showRemakeFailedModal: true });
        }
    };

    remakeCallBack = (status) => {
        const { item } = this.props;
        const slide = cssTransition({
            enter: 'animate__animated animate__slideInDown',
            exit: 'animate__animated animate__slideOutUp',
        });
        this.setState({ showRemakeModal: false });
        if (status === 'SUCCESS') {
            //show toast
            toast.configure();
            toast(this.toastContainer(), {
                toastId: `${item.sku}_${item.order_number}_${item.incremental_number}`,
                closeButton: this.closeButton,
                transition: slide,
                pauseOnHover: false,
                pauseOnFocusLoss: false,
                closeOnClick: false,
            });
        } else {
            this.setState({ showRemakeFailedModal: true });
        }
    };

    closeButton = () => {
        return (
            <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => toast.dismiss()}
            >
                <rect
                    x="1.06055"
                    width="19.7"
                    height="1.5"
                    transform="rotate(45 1.06055 0)"
                    fill="white"
                />
                <rect
                    width="19.7"
                    height="1.5"
                    transform="matrix(-0.707107 0.707107 0.707107 0.707107 13.9302 0)"
                    fill="white"
                />
            </svg>
        );
    };
    toastContainer = () => {
        const { item } = this.props;
        const itemSKUToShow =
            item.item_type === 'Addons' ? item.sku : item.sku.split('_')[0];
        return (
            <div className={`${base}__customToast`}>
                <svg
                    width="30"
                    height="21"
                    viewBox="0 0 30 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M0.776855 9.63637L11.8183 20.6778L14.1325 18.3636L3.09102 7.3222L0.776855 9.63637Z"
                        fill="#18D665"
                    />
                    <path
                        d="M11.8181 20.6778L29.405 3.09092L27.0908 0.776749L9.50391 18.3636L11.8181 20.6778Z"
                        fill="#18D665"
                    />
                </svg>
                <p className="message">{itemSKUToShow} remake successful</p>
            </div>
        );
    };

    render() {
        const {
            item,
            UserStore: { selectedHub },
            LiveDispatchStore: { remakeLoading, SKURemakeErrorMsg },
        } = this.props;
        const isOnDemandProduction = selectedHub.isOnDemandProduction;
        const { showRemakeModal, showRemakeFailedModal } = this.state;
        const itemSKUToShow =
            item.item_type === 'Addons' ? item.sku : item.sku.split('_')[0];
        const remakeIconSrc =
            item.status === ITEM_STATUS.PRODUCTION
                ? 'https://d2wu471yepgs9e.cloudfront.net/remake_complete_black.png'
                : 'https://d2wu471yepgs9e.cloudfront.net/remake_complete_black.png';
        return (
            <Fragment>
                <div
                    className={`${base}__item item-status ${
                        item.status !== ITEM_STATUS.ROUTE
                            ? item.status.toLowerCase()
                            : item.item_type === 'Addons'
                            ? 'on_route'
                            : 'on_route'
                    }`}
                    onClick={() => {
                        isOnDemandProduction &&
                            this.setState({ showRemakeModal: true });
                    }}
                >
                    {itemSKUToShow}
                    {isOnDemandProduction && (
                        <img
                            src={remakeIconSrc}
                            className="remake_icon"
                            alt="recycle icon"
                        />
                    )}

                    {item.status === ITEM_STATUS.IN_WARMER && (
                        <span className="cycle-no">
                            {item.cycle_number ? item.cycle_number : ''}
                        </span>
                    )}
                </div>
                {showRemakeModal && (
                    <Modal width={'45%'} padding={'0px'} borderRadius={'0px'}>
                        {remakeLoading ? (
                            <div className={`${base}__remake_modal`}>
                                Loading..
                            </div>
                        ) : (
                            <div className={`${base}__remake_modal`}>
                                <h3 className={`${base}__remake_modal_title`}>
                                    {itemSKUToShow} Remake
                                </h3>
                                <p className={`${base}__remake_modal_desc`}>
                                    You are taking one {itemSKUToShow} from
                                    stock.
                                </p>
                                <div className={`${base}__submit_cancel`}>
                                    <button
                                        className={`${base}__button cancel`}
                                        onClick={() => {
                                            this.setState({
                                                showRemakeModal: false,
                                            });
                                        }}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className={`${base}__button confirm`}
                                        onClick={this.handleRemakeConfirmation}
                                    >
                                        Confirm
                                    </button>
                                </div>
                            </div>
                        )}
                    </Modal>
                )}
                {showRemakeFailedModal && (
                    <Modal width={'46%'} padding={'0px'} borderRadius={'0px'}>
                        <div className={`${base}__remake_modal`}>
                            <h3
                                className={`${base}__remake_modal_title remake-error`}
                            >
                                {itemSKUToShow} Remake Failed
                            </h3>
                            <p
                                className={`${base}__remake_modal_desc remake-error`}
                            >
                                {SKURemakeErrorMsg ||
                                    'Error occurred. Please try again or contact IT'}
                            </p>
                            <button
                                className={`${base}__button dismiss`}
                                onClick={() => {
                                    this.setState({
                                        showRemakeFailedModal: false,
                                    });
                                }}
                            >
                                Dismiss
                            </button>
                        </div>
                    </Modal>
                )}
            </Fragment>
        );
    }
}

export default SKUItem;
