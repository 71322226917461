import React from 'react';

export default () => {
    return (
        <svg version="1.1" style={{ display: 'none' }}>
            <symbol id="icon-chevron-thin-up" viewBox="0 0 20 20">
                <path d="M2.582 13.891c-0.272 0.268-0.709 0.268-0.979 0s-0.271-0.701 0-0.969l7.908-7.83c0.27-0.268 0.707-0.268 0.979 0l7.908 7.83c0.27 0.268 0.27 0.701 0 0.969s-0.709 0.268-0.978 0l-7.42-7.141-7.418 7.141z"></path>
            </symbol>
            <symbol id="icon-chevron-thin-down" viewBox="0 0 20 20">
                <path d="M17.418 6.109c0.272-0.268 0.709-0.268 0.979 0s0.271 0.701 0 0.969l-7.908 7.83c-0.27 0.268-0.707 0.268-0.979 0l-7.908-7.83c-0.27-0.268-0.27-0.701 0-0.969s0.709-0.268 0.979 0l7.419 7.141 7.418-7.141z"></path>
            </symbol>
            <symbol id="icon-chevron-thin-right" viewBox="0 0 20 20">
                <path d="M13.25 10l-7.141-7.42c-0.268-0.27-0.268-0.707 0-0.979 0.268-0.27 0.701-0.27 0.969 0l7.83 7.908c0.268 0.271 0.268 0.709 0 0.979l-7.83 7.908c-0.268 0.271-0.701 0.27-0.969 0s-0.268-0.707 0-0.979l7.141-7.417z"></path>
            </symbol>
            <symbol id="icon-chevron-thin-left" viewBox="0 0 20 20">
                <path d="M13.891 17.418c0.268 0.272 0.268 0.709 0 0.979s-0.701 0.271-0.969 0l-7.83-7.908c-0.268-0.27-0.268-0.707 0-0.979l7.83-7.908c0.268-0.27 0.701-0.27 0.969 0s0.268 0.709 0 0.979l-7.141 7.419 7.141 7.418z"></path>
            </symbol>
            <symbol id="icon-chevron-double-right" viewBox="0 0 20 20">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9,8c0-0.28-0.11-0.53-0.29-0.71l-4-4C4.53,3.11,4.28,3,4,3C3.45,3,3,3.45,3,4c0,0.28,0.11,0.53,0.29,0.71L6.59,8l-3.29,3.29C3.11,11.47,3,11.72,3,12c0,0.55,0.45,1,1,1c0.28,0,0.53-0.11,0.71-0.29l4-4C8.89,8.53,9,8.28,9,8z M13.71,7.29l-4-4C9.53,3.11,9.28,3,9,3C8.45,3,8,3.45,8,4c0,0.28,0.11,0.53,0.29,0.71L11.59,8l-3.29,3.29C8.11,11.47,8,11.72,8,12c0,0.55,0.45,1,1,1c0.28,0,0.53-0.11,0.71-0.29l4-4C13.89,8.53,14,8.28,14,8C14,7.72,13.89,7.47,13.71,7.29z"
                />
            </symbol>
            <symbol id="icon-chevron-double-left" viewBox="0 0 20 20">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.41,8l3.29-3.29C7.89,4.53,8,4.28,8,4c0-0.55-0.45-1-1-1C6.72,3,6.47,3.11,6.29,3.29l-4,4C2.11,7.47,2,7.72,2,8c0,0.28,0.11,0.53,0.29,0.71l4,4C6.47,12.89,6.72,13,7,13c0.55,0,1-0.45,1-1c0-0.28-0.11-0.53-0.29-0.71L4.41,8z M9.41,8l3.29-3.29C12.89,4.53,13,4.28,13,4c0-0.55-0.45-1-1-1c-0.28,0-0.53,0.11-0.71,0.29l-4,4C7.11,7.47,7,7.72,7,8c0,0.28,0.11,0.53,0.29,0.71l4,4C11.47,12.89,11.72,13,12,13c0.55,0,1-0.45,1-1c0-0.28-0.11-0.53-0.29-0.71L9.41,8z"
                />
            </symbol>
            <symbol id="icon-cross" viewBox="0 0 24 24">
                <path d="M19 4q0.43 0 0.715 0.285t0.285 0.715q0 0.422-0.289 0.711l-6.297 6.289 6.297 6.289q0.289 0.289 0.289 0.711 0 0.43-0.285 0.715t-0.715 0.285q-0.422 0-0.711-0.289l-6.289-6.297-6.289 6.297q-0.289 0.289-0.711 0.289-0.43 0-0.715-0.285t-0.285-0.715q0-0.422 0.289-0.711l6.297-6.289-6.297-6.289q-0.289-0.289-0.289-0.711 0-0.43 0.285-0.715t0.715-0.285q0.422 0 0.711 0.289l6.289 6.297 6.289-6.297q0.289-0.289 0.711-0.289z"></path>
            </symbol>
            <symbol id="icon-printer" viewBox="0 0 16 16">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12,2.02c0-0.55-0.45-1-1-1H5c-0.55,0-1,0.45-1,1v1h8V2.02z M15,4.02H1c-0.55,0-1,0.45-1,1v6c0,0.55,0.45,1,1,1h1v-3h12v3h1c0.55,0,1-0.45,1-1v-6C16,4.46,15.55,4.02,15,4.02z M14,7.02h-2v-1h2V7.02zM11,13.02H5v-3H3v4c0,0.55,0.45,1,1,1h8c0.55,0,1-0.45,1-1v-4h-2V13.02z"
                />
            </symbol>
            <symbol id="icon-calendar" viewBox="0 0 16 16">
                <path
                    d="M11,3 C11.6,3 12,2.5 12,2 L12,1 C12,0.4 11.6,0 11,0 C10.4,0 10,0.4 10,1 L10,2 C10,2.5 10.4,3 11,3 Z M14,1 L13,1 L13,2 C13,3.1 12.1,4 11,4 C9.9,4 9,3.1 9,2 L9,1 L6,1 L6,2 C6,3.1 5.1,4 4,4 C2.9,4 2,3.1 2,2 L2,1 L1,1 C0.4,1 0,1.5 0,2 L0,14 C0,14.6 0.4,15 1,15 L14,15 C14.6,15 15,14.6 15,14 L15,2 C15,1.4 14.5,1 14,1 Z M5,13 L2,13 L2,10 L5,10 L5,13 Z M5,9 L2,9 L2,6 L5,6 L5,9 Z M9,13 L6,13 L6,10 L9,10 L9,13 Z M9,9 L6,9 L6,6 L9,6 L9,9 Z M13,13 L10,13 L10,10 L13,10 L13,13 Z M13,9 L10,9 L10,6 L13,6 L13,9 Z M4,3 C4.6,3 5,2.5 5,2 L5,1 C5,0.4 4.6,0 4,0 C3.4,0 3,0.4 3,1 L3,2 C3,2.5 3.4,3 4,3 Z"
                    id="Shape"
                    fill="#000000"
                    fillRule="nonzero"
                ></path>
            </symbol>
            <symbol id="icon-intersection" viewBox="0 0 16 16">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10,3C9.08,3,8.24,3.26,7.5,3.69C6.76,3.26,5.92,3,5,3C2.24,3,0,5.24,0,8s2.24,5,5,5c0.92,0,1.76-0.26,2.5-0.69C8.24,12.74,9.08,13,10,13c2.76,0,5-2.24,5-5S12.76,3,10,3z M5.9,10.85C5.61,10.94,5.31,11,5,11c-1.66,0-3-1.34-3-3s1.34-3,3-3c0.31,0,0.61,0.06,0.9,0.15C5.33,5.96,5,6.94,5,8C5,9.06,5.33,10.04,5.9,10.85z M10,11c-0.31,0-0.61-0.06-0.9-0.15C9.67,10.04,10,9.06,10,8c0-1.06-0.33-2.04-0.9-2.85C9.39,5.06,9.69,5,10,5c1.66,0,3,1.34,3,3S11.66,11,10,11z"
                />
            </symbol>
            <symbol id="icon-arrow-top-right" viewBox="0 0 16 16">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13,2H6C5.45,2,5,2.45,5,3c0,0.55,0.45,1,1,1h4.59l-8.29,8.29C2.11,12.47,2,12.72,2,13c0,0.55,0.45,1,1,1c0.28,0,0.53-0.11,0.71-0.29L12,5.41V10c0,0.55,0.45,1,1,1s1-0.45,1-1V3C14,2.45,13.55,2,13,2z"
                />
            </symbol>
            <symbol id="icon-caret-down" viewBox="0 0 16 16">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12,6.5C12,6.22,11.78,6,11.5,6h-7C4.22,6,4,6.22,4,6.5c0,0.13,0.05,0.24,0.13,0.33c0,0,0,0,0,0l3.5,4l0,0C7.72,10.93,7.85,11,8,11s0.28-0.07,0.37-0.17l0,0l3.5-4l0,0C11.95,6.74,12,6.63,12,6.5z"
                />
            </symbol>
            <symbol id="icon-checkmark-checked" viewBox="0 0 17 17">
                <g transform="translate(1 1)" fill="none" fillRule="evenodd">
                    <path
                        stroke="#18d665"
                        strokeWidth=".5"
                        fill="none"
                        d="M0 15.62h15.62V0H0z"
                    />
                    <path
                        d="M12.73 4.511l-6.697 6.696L3.295 8.47"
                        strokeWidth="1.2"
                        stroke="#18d665"
                    />
                </g>
            </symbol>
            <symbol id="icon-checkmark-unchecked" viewBox="0 0 17 17">
                <path
                    stroke="#f26865"
                    strokeWidth=".5"
                    fill="none"
                    d="M1 16.62h15.62V1H1z"
                    fillRule="evenodd"
                />
            </symbol>
            <symbol id="icon-plus" viewBox="0 0 24 24">
                <path d="M12 2q0.414 0 0.707 0.293t0.293 0.707v8h8q0.414 0 0.707 0.293t0.293 0.707-0.293 0.707-0.707 0.293h-8v8q0 0.414-0.293 0.707t-0.707 0.293-0.707-0.293-0.293-0.707v-8h-8q-0.414 0-0.707-0.293t-0.293-0.707 0.293-0.707 0.707-0.293h8v-8q0-0.414 0.293-0.707t0.707-0.293z"></path>
            </symbol>
            <symbol id="icon-minus" viewBox="0 0 24 24">
                <path d="M3 11h18q0.414 0 0.707 0.293t0.293 0.707-0.293 0.707-0.707 0.293h-18q-0.414 0-0.707-0.293t-0.293-0.707 0.293-0.707 0.707-0.293z"></path>
            </symbol>
            <symbol
                id="icon-caution-square"
                width="44"
                height="44"
                fill="none"
                viewBox="0 0 44 44"
            >
                <g clipPath="url(#prefix__clip0)">
                    <rect
                        width="43.069"
                        height="43.069"
                        fill="#FFA300"
                        rx="5.301"
                    />
                    <path
                        fill="#fff"
                        d="M19.86 11.51h4.032l-.535 13.76h-2.961l-.536-13.76zm.423 19.09c-.433-.413-.649-.92-.649-1.522s.216-1.11.649-1.523c.432-.414.95-.62 1.55-.62.621 0 1.148.206 1.58.62.451.395.677.902.677 1.523 0 .62-.226 1.137-.677 1.55-.432.396-.959.593-1.58.593-.6 0-1.118-.207-1.55-.62z"
                    />
                </g>
                <defs>
                    <clipPath id="prefix__clip0">
                        <path fill="#fff" d="M0 0H43.069V43.069H0z" />
                    </clipPath>
                </defs>
            </symbol>
            <symbol
                id="icon-caution-triangle"
                width="62"
                height="43"
                fill="none"
                viewBox="0 0 62 43"
            >
                <g clipPath="url(#prefix__clip0)">
                    <path
                        fill="#FF5A5F"
                        d="M27.758 1.842c1.418-2.456 4.963-2.456 6.381 0l20.42 35.37c1.419 2.456-.354 5.526-3.19 5.526H10.528c-2.836 0-4.61-3.07-3.191-5.526l20.42-35.37z"
                    />
                    <path
                        fill="#fff"
                        d="M28.718 12.446h4.484l-.596 15.303h-3.293l-.595-15.303zm.47 21.23c-.48-.46-.721-1.024-.721-1.693s.24-1.233.72-1.693c.482-.46 1.057-.69 1.726-.69.69 0 1.275.23 1.756.69.502.439.753 1.003.753 1.693 0 .69-.251 1.265-.753 1.725-.481.439-1.066.658-1.756.658-.67 0-1.244-.23-1.725-.69z"
                    />
                </g>
                <defs>
                    <clipPath id="prefix__clip0">
                        <path fill="#fff" d="M0 0H61.897V42.738H0z" />
                    </clipPath>
                </defs>
            </symbol>
            <symbol
                id="icon-check-circle"
                width="42"
                height="42"
                fill="none"
                viewBox="0 0 42 42"
            >
                <g clipPath="url(#prefix__clip0)">
                    <circle cx="21.104" cy="20.604" r="20.604" fill="#18D665" />
                    <path
                        fill="#fff"
                        d="M9.95 18.302l9.079 8.678 1.902-1.819-9.078-8.677-1.903 1.818z"
                    />
                    <path
                        fill="#fff"
                        d="M19.029 26.98l14.46-13.822-1.903-1.818-14.46 13.821 1.902 1.819z"
                    />
                </g>
                <defs>
                    <clipPath id="prefix__clip0">
                        <path
                            fill="#fff"
                            d="M0 0H41.209V41.209H0z"
                            transform="translate(.5)"
                        />
                    </clipPath>
                </defs>
            </symbol>
            <symbol
                id="icon-print"
                width="29"
                height="29"
                fill="none"
                viewBox="0 0 29 29"
            >
                <path
                    stroke="#1A1A1A"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2.492"
                    d="M19.938 12.688h2.718M6.344 22.655H1.812v-14.5h25.375v14.5H6.345zm0-5.437v9.968h16.312V17.22H6.344zm16.312-9.063V1.812H6.344v6.344h16.312z"
                />
            </symbol>
            <symbol
                id="icon-print-small"
                width="17"
                height="16"
                fill="none"
                viewBox="0 0 17 16"
            >
                <g clipPath="url(#prefix__clip0)">
                    <path
                        stroke="#1A1A1A"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.375"
                        d="M11.5 7H13m-9 5.5H1.5v-8h14v8H4zm0-3V15h9V9.5H4zm9-5V1H4v3.5h9z"
                    />
                </g>
                <defs>
                    <clipPath id="prefix__clip0">
                        <path
                            fill="#fff"
                            d="M0 0H16V16H0z"
                            transform="translate(.5)"
                        />
                    </clipPath>
                </defs>
            </symbol>
            <symbol
                id="icon-print-medium"
                width="20"
                height="20"
                fill="none"
                viewBox="0 0 20 20"
            >
                <path
                    stroke="#1A1A1A"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.375"
                    d="M13.75 8.75h1.875m-11.25 6.875H1.25v-10h17.5v10H4.375zm0-3.75v6.875h11.25v-6.875H4.375zm11.25-6.25V1.25H4.375v4.375h11.25z"
                />
            </symbol>
            <symbol
                id="icon-print-off"
                width="180"
                height="180"
                fill="none"
                viewBox="0 0 180 180"
            >
                <path
                    fill="#000"
                    d="M45 22.5v8.85L66.15 52.5H135v-30H45zm-27.9 0l-9.6 9.525L35.625 60c-5.624.505-10.856 3.092-14.672 7.254-3.815 4.162-5.938 9.6-5.953 15.246v45h30v30h87.975l15 15 9.525-9.6L17.1 22.5zM73.65 60l67.5 67.5H165v-45c0-5.967-2.371-11.69-6.59-15.91-4.22-4.22-9.943-6.59-15.91-6.59H73.65zm68.85 15c1.989 0 3.897.79 5.303 2.197C149.21 78.603 150 80.51 150 82.5c0 1.99-.79 3.897-2.197 5.303C146.397 89.21 144.489 90 142.5 90c-1.989 0-3.897-.79-5.303-2.197C135.79 86.397 135 84.49 135 82.5c0-1.99.79-3.897 2.197-5.303C138.603 75.79 140.511 75 142.5 75zM60 105h20.475l37.5 37.5H60V105z"
                />
            </symbol>
            <symbol
                id="icon-bell"
                width="40"
                height="40"
                fill="none"
                viewBox="0 0 40 40"
            >
                <path
                    fill="#FB275F"
                    d="M12.633 6.8L10.25 4.417c-4 3.05-6.633 7.75-6.866 13.083h3.333c.118-2.114.711-4.173 1.736-6.026 1.024-1.853 2.453-3.45 4.18-4.674zm20.65 10.7h3.334c-.25-5.333-2.883-10.033-6.867-13.083L27.384 6.8c1.72 1.23 3.142 2.83 4.163 4.68 1.021 1.852 1.614 3.91 1.736 6.02zM30 18.333c0-5.116-2.733-9.4-7.5-10.533V6.667c0-1.384-1.116-2.5-2.5-2.5-1.383 0-2.5 1.116-2.5 2.5V7.8C12.717 8.933 10 13.2 10 18.333v8.334L6.667 30v1.667h26.667V30L30 26.667v-8.334zM20 36.667c.233 0 .45-.017.667-.067 1.083-.233 1.966-.967 2.4-1.967.166-.4.25-.833.25-1.3H16.65c.017 1.834 1.5 3.334 3.35 3.334z"
                />
            </symbol>
            <symbol
                id="icon-timer"
                width="97"
                height="97"
                fill="none"
                viewBox="0 0 97 97"
            >
                <path
                    fill="#767676"
                    d="M45.469 33.344h6.062v27.281H45.47V33.344zM39.406 6.063h18.188v6.062H39.406V6.062z"
                />
                <path
                    fill="#767676"
                    d="M86.356 23.908l-4.304-4.274-6.82 6.82c-5.584-6.448-13.436-10.496-21.926-11.305-8.49-.808-16.966 1.686-23.665 6.963-6.7 5.278-11.108 12.934-12.31 21.378-1.202 8.443.896 17.025 5.857 23.962C28.15 74.39 35.592 79.15 43.971 80.741c8.379 1.592 17.049-.105 24.209-4.739 7.16-4.634 12.26-11.848 14.238-20.144 1.98-8.296.687-17.035-3.61-24.403l7.548-7.547zM49.98 75.438c-5.396 0-10.67-1.6-15.157-4.597-4.486-2.998-7.983-7.258-10.048-12.243-2.064-4.985-2.605-10.47-1.552-15.763 1.052-5.292 3.65-10.153 7.466-13.968 3.815-3.816 8.677-6.414 13.969-7.466 5.292-1.053 10.777-.513 15.762 1.552 4.985 2.065 9.246 5.562 12.244 10.048 2.997 4.486 4.597 9.76 4.597 15.157 0 7.235-2.874 14.174-7.99 19.29-5.117 5.117-12.056 7.99-19.291 7.99z"
                />
            </symbol>
            <symbol
                id="icon-check"
                width="18"
                height="13"
                fill="none"
                viewBox="0 0 18 13"
            >
                <path
                    fill="#fff"
                    d="M.252 5.667L7 12.414 8.414 11 1.667 4.252.252 5.667z"
                />
                <path
                    fill="#fff"
                    d="M7 12.414L17.747 1.667 16.333.252 5.586 11 7 12.414z"
                />
            </symbol>
            <symbol
                id="icon-check-black"
                width="18"
                height="13"
                fill="none"
                viewBox="0 0 18 13"
            >
                <path
                    fill="#000"
                    d="M.252 5.667L7 12.414 8.414 11 1.667 4.252.252 5.667z"
                />
                <path
                    fill="#000"
                    d="M7 12.414L17.747 1.667 16.333.252 5.586 11 7 12.414z"
                />
            </symbol>
            <symbol
                id="icon-check-red"
                width="18"
                height="13"
                fill="none"
                viewBox="0 0 18 13"
            >
                <path
                    fill="#FB275F"
                    d="M.252 5.667L7 12.414 8.414 11 1.667 4.252.252 5.667z"
                />
                <path
                    fill="#FB275F"
                    d="M7 12.414L17.747 1.667 16.333.252 5.586 11 7 12.414z"
                />
            </symbol>
            <symbol
                id="icon-hourglass"
                width="109"
                height="109"
                viewBox="0 0 109 109"
            >
                <path
                    d="M81.75 9.08325H27.25V36.3333H27.2954L27.25 36.3787L45.4167 54.4999L27.25 72.6666L27.2954 72.712H27.25V99.9166H81.75V72.712H81.7046L81.75 72.6666L63.5833 54.4999L81.75 36.3787L81.7046 36.3333H81.75V9.08325ZM72.6667 74.9374V90.8333H36.3333V74.9374L54.5 56.7708L72.6667 74.9374ZM72.6667 34.0624L54.5 52.2291L36.3333 34.0624V18.1666H72.6667V34.0624Z"
                    fill="#BABABA"
                />
            </symbol>
            <symbol
                id="icon-cutlery"
                width="19"
                height="19"
                fill="none"
                viewBox="0 0 19 19"
            >
                <path
                    fill="#1A1A1A"
                    d="M17.1 10.45v6.65c0 .504-.2.987-.557 1.343-.356.357-.84.557-1.344.557-.504 0-.987-.2-1.343-.556-.356-.357-.557-.84-.557-1.344v-4.75h-1.9v-9.5c0-.756.3-1.48.835-2.015C12.77.3 13.494 0 14.25 0h2.85v10.45zM3.8 9.5c-.504 0-.988-.2-1.344-.556-.356-.357-.557-.84-.557-1.344V.95c0-.252.1-.494.279-.672C2.356.1 2.598 0 2.849 0c.252 0 .494.1.672.278.178.178.278.42.278.672v3.8h.95V.95c0-.252.1-.494.279-.672C5.206.1 5.448 0 5.699 0c.252 0 .494.1.672.278.178.178.278.42.278.672v3.8h.95V.95c0-.252.1-.494.279-.672C8.056.1 8.298 0 8.549 0c.252 0 .494.1.672.278.178.178.278.42.278.672V7.6c0 .504-.2.987-.556 1.344-.356.356-.84.556-1.344.556v7.6c0 .504-.2.987-.556 1.343-.356.357-.84.557-1.344.557-.503 0-.987-.2-1.343-.556-.356-.357-.557-.84-.557-1.344V9.5z"
                />
            </symbol>
            <symbol
                id="icon-bag"
                width="45"
                height="38"
                fill="none"
                viewBox="0 0 45 38"
            >
                <path
                    fill="#1A1A1A"
                    d="M45 8.6c0-.552-.448-1-1-1H32.75c0-4.199-4.034-7.6-10.25-7.6S12.25 3.401 12.25 7.6H1c-.552 0-1 .448-1 1V37c0 .552.448 1 1 1h43c.552 0 1-.448 1-1V8.6zM22.5 3.8c3.094 0 4.625 1.71 4.625 3.8h-9.25c0-2.09 1.531-3.8 4.625-3.8z"
                />
            </symbol>
            <symbol
                id="icon-bag-purple"
                width="45"
                height="38"
                fill="none"
                viewBox="0 0 45 38"
            >
                <path
                    fill="#80FF00"
                    d="M45 8.6c0-.552-.448-1-1-1H32.75c0-4.199-4.034-7.6-10.25-7.6S12.25 3.401 12.25 7.6H1c-.552 0-1 .448-1 1V37c0 .552.448 1 1 1h43c.552 0 1-.448 1-1V8.6zM22.5 3.8c3.094 0 4.625 1.71 4.625 3.8h-9.25c0-2.09 1.531-3.8 4.625-3.8z"
                />
            </symbol>
            <symbol
                id="icon-clock"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
            >
                <path
                    fill="#fff"
                    d="M12 1.5C6.202 1.5 1.5 6.202 1.5 12S6.202 22.5 12 22.5 22.5 17.798 22.5 12 17.798 1.5 12 1.5zm0 19.219c-4.814 0-8.719-3.905-8.719-8.719S7.186 3.281 12 3.281 20.719 7.186 20.719 12 16.814 20.719 12 20.719z"
                />
                <path
                    fill="#fff"
                    d="M16.095 14.967l-3.343-2.416V6.75c0-.103-.084-.188-.187-.188h-1.127c-.104 0-.188.085-.188.188v6.455c0 .06.028.117.077.152l3.877 2.827c.084.06.201.042.262-.04l.67-.914c.062-.087.043-.204-.041-.263z"
                />
            </symbol>
            <symbol
                id="icon-check-red"
                width="18"
                height="13"
                fill="none"
                viewBox="0 0 18 13"
            >
                <path
                    fill="#FB275F"
                    d="M.252 5.667L7 12.414 8.414 11 1.667 4.252.252 5.667z"
                />
                <path
                    fill="#FB275F"
                    d="M7 12.414L17.747 1.667 16.333.252 5.586 11 7 12.414z"
                />
            </symbol>
            <symbol
                id="icon-hourglass"
                width="109"
                height="109"
                viewBox="0 0 109 109"
            >
                <path
                    d="M29.8675 26.5738C29.1122 24.7848 28.0162 23.1597 26.6404 21.7892C25.2688 20.4147 23.644 19.3188 21.8557 18.562C21.8397 18.554 21.8237 18.55 21.8077 18.542C24.3021 16.7402 25.9237 13.8054 25.9237 10.4941C25.9237 5.00879 21.4794 0.564453 15.994 0.564453C10.5086 0.564453 6.06431 5.00879 6.06431 10.4941C6.06431 13.8054 7.68589 16.7402 10.1803 18.546C10.1643 18.554 10.1483 18.558 10.1323 18.566C8.33853 19.3228 6.72896 20.4078 5.34761 21.7932C3.97311 23.1648 2.87722 24.7896 2.12046 26.5778C1.37702 28.3286 0.976073 30.2056 0.939309 32.1072C0.93824 32.15 0.945736 32.1925 0.961354 32.2323C0.976972 32.2721 1.0004 32.3083 1.03025 32.3389C1.0601 32.3695 1.09577 32.3938 1.13516 32.4104C1.17456 32.427 1.21688 32.4356 1.25962 32.4355H3.66197C3.83814 32.4355 3.97827 32.2954 3.98228 32.1232C4.06236 29.0322 5.30357 26.1374 7.49771 23.9433C9.76792 21.673 12.7829 20.4238 15.994 20.4238C19.2051 20.4238 22.2201 21.673 24.4903 23.9433C26.6844 26.1374 27.9256 29.0322 28.0057 32.1232C28.0097 32.2994 28.1499 32.4355 28.326 32.4355H30.7284C30.7711 32.4356 30.8134 32.427 30.8528 32.4104C30.8922 32.3938 30.9279 32.3695 30.9578 32.3389C30.9876 32.3083 31.011 32.2721 31.0266 32.2323C31.0423 32.1925 31.0498 32.15 31.0487 32.1072C31.0086 30.1934 30.6123 28.3315 29.8675 26.5738ZM15.994 17.3809C14.1562 17.3809 12.4265 16.6642 11.1252 15.3629C9.82398 14.0616 9.10728 12.3319 9.10728 10.4941C9.10728 8.65635 9.82398 6.92666 11.1252 5.62539C12.4265 4.32412 14.1562 3.60742 15.994 3.60742C17.8318 3.60742 19.5615 4.32412 20.8627 5.62539C22.164 6.92666 22.8807 8.65635 22.8807 10.4941C22.8807 12.3319 22.164 14.0616 20.8627 15.3629C19.5615 16.6642 17.8318 17.3809 15.994 17.3809Z"
                    fill="black"
                />
            </symbol>
        </svg>
    );
};
