import { action, computed, observable, toJS } from 'mobx';
import * as api from '../services/api';
import UserStore from './common/UserStore';
import { differenceWith, isEqual } from 'lodash';
import moment from 'moment';

class QueueScreenStore {
    @observable loading = true;
    @observable queuedOrders = null;
    @observable ordersDone = [];
    @observable ordersToShow = [];
    @observable date = moment().format('DD-MM-YYYY');

    queuedOrdersLastModified = null;

    @action orderShown = (order) => {
        //this function will remove the order that's already shown from ordersToShow array
        this.ordersToShow = this.ordersToShow.filter(
            (orderToShow) => orderToShow.order_number !== order.order_number
        );
        //check whether the order already exist in ordersDone or not.
        if (
            !this.ordersDone.find(
                (orderDone) => orderDone.order_number === order.order_number
            )
        ) {
            //order doesn't exist in ordersDone
            //shift at index 0
            this.ordersDone.unshift(order);
        }
    };

    getSortedQueuedOrders(orders) {
        const pendingPreparedOrders = orders.filter(
            (order) => order.status !== 'Completed'
        );
        const sortedDineInPickUpOrders = pendingPreparedOrders.map((order) => {
            const common_timestamp = order.ready_for_pickup_at
                ? order.ready_for_pickup_at
                : order.order_date;
            return { ...order, common_timestamp };
        });
        const sortedPendingPreparedOrders = sortedDineInPickUpOrders.sort(
            (a, b) => b.common_timestamp - a.common_timestamp
        );
        return sortedPendingPreparedOrders;
    }

    filterOrders(orders) {
        //filter orders having no item
        return orders.filter((order) => {
            if (order.ready_for_pickup_at) {
                //this is pickup order
                return true;
            } else {
                //this is dine in order
                if (order.items && order.items.length > 0) {
                    //items exist in order
                    return true;
                } else {
                    //items doesn't exist in order
                    return false;
                }
            }
        });
    }

    @action handleGetQueuedOrders = ({
        forceLatest = false,
        date = this.date,
        token = null,
        hubCode = null,
    } = {}) => {
        let ifModifiedSince = null;
        if (!forceLatest) {
            ifModifiedSince = this.queuedOrdersLastModified;
        }
        api.getQueuedOrders(date, ifModifiedSince, token, hubCode)
            .then((res) => {
                if (res.modified) {
                    const { lastModified, responseBody } = res;
                    this.queuedOrdersLastModified = lastModified;

                    if (!responseBody.list) {
                        //there's no order for queue screen.
                        this.queuedOrders = [];
                        this.ordersToShow = [];
                        this.ordersDone = [];
                    }

                    //this array will be used to filter out completed orders from prepared/Done orders list
                    let preparedOrders = [];
                    if (responseBody.list && responseBody.list.length > 0) {
                        const sortedQueuedOrders =
                            this.getSortedQueuedOrders(responseBody.list) || [];
                        this.queuedOrders =
                            this.filterOrders(sortedQueuedOrders) || [];

                        for (let order of this.queuedOrders.filter(
                            (order) => order.status === 'Prepared'
                        )) {
                            //pushing the order to ordersToShow if the order  already doesn't exist in both ordersToShow and ordersDone
                            if (
                                !this.ordersToShow.find(
                                    (orderToShow) =>
                                        orderToShow.order_number ===
                                        order.order_number
                                ) &&
                                !this.ordersDone.find(
                                    (orderDone) =>
                                        orderDone.order_number ===
                                        order.order_number
                                )
                            ) {
                                this.ordersToShow.unshift(order);
                            }

                            if (
                                this.ordersDone.find(
                                    (orderDone) =>
                                        orderDone.order_number ===
                                        order.order_number
                                )
                            ) {
                                //it's prepared order, not completed one
                                preparedOrders.push(order.order_number);
                            }
                        }
                        //this is used to remove orders which have been completed from prepared orders
                        this.ordersDone = this.ordersDone.filter((order) =>
                            preparedOrders.includes(order.order_number)
                        );
                    }
                } else {
                    // no change since the last call
                }
                this.loading = false;
            })
            .catch((err) => {
                console.log(err);
                UserStore.message = err.message;
            });
    };
}

const store = new QueueScreenStore();
export default store;
