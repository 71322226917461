import React from 'react';

const base = 'warmer-screen';

export default function WarmerSkuCard({ sku, quantity }) {
    return (
        <div className={`${base}__sku_card`}>
            <div className={`${base}__card_sku`}>{sku}</div>
            <div className={`${base}__card_quantity_cont`}>
                <span className={`${base}__quantity_x`}>x</span>
                <span className={`${base}__quantity`}>{quantity}</span>
            </div>
        </div>
    );
}
