import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { DateInput } from '@blueprintjs/datetime';
import { Icon } from '@blueprintjs/core';
import SwitchButton from 'lyef-switch-button';
import moment from 'moment';
import { getFromLocalStorage } from '../../services/utils';
const base = 'secondary-header';

@inject('LiveDispatchStore', 'UserStore')
@observer
class DispatchHeader extends Component {
    state = {
        currentTime: null,
        statusBarColor: '#F4594F'
    };

    componentDidMount = () => {
        this.currentTime();
    };

    componentWillUnmount = () => {
        if (this.timeOut) {
            clearTimeout(this.timeOut);
        }
    };

    handleSetDate = (forceLatest, momentDate) => {
        const {
            LiveDispatchStore: { handleGetDispatches },
        } = this.props;
        const date = moment(momentDate).format('DD-MM-YYYY');

        handleGetDispatches(forceLatest, date);
    };

    currentTime = () => {
        const currentTime = moment().format('LTS');
        if (moment().second() % 2 === 0){
            this.setState({statusBarColor: 'black'})
        } else {
            this.setState({statusBarColor: '#F4594F'})
        }
        this.setState({ currentTime });
        this.timeOut = setTimeout(this.currentTime, 1000);
    };

    handleToggleSwitch = () => {
        const {
            LiveDispatchStore: { setAutoPrint, isAutoPrint },
        } = this.props;
        setAutoPrint(!isAutoPrint);
    };

    componentWillUnmount() {
        clearTimeout(this.currentTime);
    }

    render() {
        const { currentTime } = this.state;
        const {
            goFullscreen,
            LiveDispatchStore: { date, closedFoodPandaStores, recentlyClosedFoodPandaStores }
        } = this.props;

        return (
            <div className={`${base}__wrapper bg--white`}>
                <div className={`${base} display--flex  pll prl ptm pbm`}>
                    <div
                        className={`${base}__left display--flex justify--space-between flex-align-items-center`}
                    >
                        <DateInput
                            formatDate={(date) =>
                                moment(date).date() === moment().date()
                                    ? moment(date).format(
                                          '[Today •] DD/MM/YYYY'
                                      )
                                    : moment(date).format('DD/MM/YYYY')
                            }
                            onChange={(date) => this.handleSetDate(true, date)}
                            parseDate={(str) =>
                                moment(str, 'DD/MM/YYYY').toDate()
                            }
                            placeholder="Pick a date"
                            value={moment(date, 'DD/MM/YYYY').toDate()}
                            inputProps={{ leftIcon: 'calendar' }}
                        />
                        <p className="fs--medium-medium-large m0 mlm">
                            {currentTime}
                        </p>
                        {recentlyClosedFoodPandaStores.length === 0 && closedFoodPandaStores.length > 0
                            && <p className="fs--medium-medium-large m0 mlm" style={{
                            backgroundColor: this.state.statusBarColor,
                            borderRadius: '32px',
                            color: 'white',
                            padding: '8px 16px'
                        }}>
                            ALERT {closedFoodPandaStores.map(p => (<span key={p}>{p + " "}</span>))} is closed! Re-open now!
                        </p>
                        }
                    </div>
                    <div
                        className={`${base}__right display--flex justify--space-between flex-align-items-center`}
                    >
                        <button
                            className={`btn btn-borderless mls`}
                            onClick={() => goFullscreen()}
                        >
                            <Icon icon="fullscreen" iconSize={24} />
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default DispatchHeader;
