import { observable, action, computed, toJS } from 'mobx';
import * as api from '../services/api';
import UserStore from './common/UserStore';
import { saveToLocalStorage, getFromLocalStorage } from '../services/utils';
import moment from 'moment';
import ScanningStore from './common/ScanningStore';

class DispatchStore {
    @observable date = moment().format('DD-MM-YYYY');
    @observable loading = true;
    @observable scannerLoading = false;
    @observable dispatchList = [];
    @observable selectedDispatchIndex =
        getFromLocalStorage('selectedDispatchIndex') || 0;
    @observable routeListForDispatch = [];
    @observable selectedRoutesForDispatch = [];
    @observable dispatchLabelSizeTypes = ['S', 'L'];
    @observable dispatchLabelSize =
        getFromLocalStorage('dispatchLabelSize') ||
        this.dispatchLabelSizeTypes[0];
    @observable isAutoPrint = getFromLocalStorage('isAutoPrint') || false;
    @observable scannedItem = null;
    @observable scannedItemSKU = '';
    @observable printingStatus = '';
    @observable scannedItemError = '';
    @observable showScannedItemOnLiveDispatch = false;

    ON_ROUTE = 'ON_ROUTE';
    IN_WARMER = 'IN_WARMER';
    IN_PRODUCTION = 'IN_PRODUCTION';

    DINE_IN = 'DINE_IN';
    TAKE_AWAY = 'TAKE_AWAY';

    PRINTING = 'Printing';
    PRINTING_COMPLETE = 'Printing Compelete';
    PRINTING_FAILED = 'Printing Failed';

    @computed get selectedDispatch() {
        const selectedDispatch = this.dispatchList[this.selectedDispatchIndex];
        if (selectedDispatch) {
            return selectedDispatch;
        }
        return this.dispatchList[this.dispatchList.length - 1];
    }

    @action setAutoPrint = (isAutoPrint) => {
        this.isAutoPrint = isAutoPrint;
        saveToLocalStorage('isAutoPrint', isAutoPrint);
    };

    @action handleGetDispatches = (date, loading = false) => {
        if (loading) this.loading = true;
        this.date = date ? moment(date).format('DD-MM-YYYY') : this.date;
        api.getDispatchListForDate(this.date)
            .then((res) => {
                this.dispatchList = res.dispatches || [];

                const selectedDispatch = this.dispatchList[
                    this.selectedDispatchIndex
                ];
                if (selectedDispatch) {
                    this.setSelectedDispatchIndex(this.selectedDispatchIndex);
                } else {
                    const selectedDispatchIndex = this.dispatchList.length
                        ? this.dispatchList.length - 1
                        : 0;
                    this.setSelectedDispatchIndex(selectedDispatchIndex);
                }
            })
            .catch((err) => {
                UserStore.message = err.message;
            });
    };

    @action handleGetRoutes = () => {
        if (this.selectedDispatch) {
            api.getRoutesForDateAndDispatch(
                this.date,
                this.selectedDispatch.dispatch_no
            )
                .then((res) => {
                    this.routeListForDispatch = res.routes.filter(
                        (route) => route.order
                    );
                    this.selectedRoutesForDispatch = this
                        .selectedRoutesForDispatch.length
                        ? this.selectedRoutesForDispatch
                        : this.routeListForDispatch.map(
                              (route) => route.route_no
                          );
                })
                .catch((err) => {
                    UserStore.message = err.message;
                })
                .finally(() => {
                    this.loading = false;

                    let mealIds = [];
                    this.routeListForDispatch.forEach((route) => {
                        if (route.order) {
                            route.order.forEach((order) => {
                                order.menu_item.forEach((item) => {
                                    if (!mealIds.includes(item.id)) {
                                        mealIds.push(item.id);
                                    }
                                });
                            });
                        }
                    });
                    this.handleGetChefNames(mealIds);
                });
        } else {
            this.loading = false;
        }
    };

    @action handleGetChefNames = (list) => {
        if (this.routeListForDispatch.length) {
            api.getChefNames({ list })
                .then((res) => {
                    this.chefNames = res;
                })
                .catch((err) => {
                    UserStore.message = err.message;
                });
        }
    };

    @action setSelectedDispatchIndex = (dispatchIndex) => {
        this.selectedDispatchIndex = dispatchIndex;
        saveToLocalStorage('selectedDispatchIndex', dispatchIndex);
        this.selectedRoutesForDispatch = [];
        this.handleGetRoutes();
    };

    @action setSelectedRoutesForDispatch = (routeNo) => {
        this.selectedRoutesForDispatch;

        if (this.selectedRoutesForDispatch.includes(routeNo)) {
            this.selectedRoutesForDispatch = this.selectedRoutesForDispatch.filter(
                (route) => route !== routeNo
            );
        } else {
            this.selectedRoutesForDispatch.push(routeNo);
        }
    };

    @action setDispatchLabelSize = (size) => {
        this.dispatchLabelSize = size;
        saveToLocalStorage('dispatchLabelSize', size);
    };

    onScannedItemPrintingFailed() {
        this.printingStatus = this.PRINTING_FAILED;
    }

    onScannedItemPrintingSuccess() {
        this.printingStatus = this.PRINTING_COMPLETE;
    }

    @action handledScannedItem = (
        todayMenuId,
        incrementalNumber,
        pickUpNumber = null
    ) => {
        this.scannerLoading = true;
        this.showScannedItemOnLiveDispatch = true;
        api.handledScannedItem(
            todayMenuId,
            this.date,
            incrementalNumber,
            pickUpNumber
        )
            .then((res) => {
                this.scannedItem = res;

                if (
                    this.scannedItem.item &&
                    this.scannedItem.item.status === this.ON_ROUTE &&
                    this.isAutoPrint
                ) {
                    this.printingStatus = this.PRINTING;
                    // this.printScannedItem()
                } else {
                    this.printingStatus = '';
                }

                this.scannedItemError = '';
            })
            .catch((err) => {
                this.scannedItem = null;
                this.scannedItemError = err.message;
                console.log(err);
            })
            .finally(() => {
                this.scannerLoading = false;
                setTimeout(() => {
                    this.showScannedItemOnLiveDispatch = false;
                }, 5000);
            });
    };
}

const store = new DispatchStore();
export default store;
