import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import Loading from '../Loading';
import Modal from '../Modal';
import InventoryHeader from './InventoryHeader';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import SKURowAdjustmentUpdate from './SKURowAdjustmentUpdate';
import InventoryBatchHistoryModal from './InventoryBatchHistoryModal';
import InventoryComponentStockUpdate from './InventoryComponentStockUpdate';
import UserStore from '../../stores/common/UserStore';
import Select from 'react-select';

const base = 'inventory-adjustment';

@inject('InventoryStore')
@observer
class InventoryAdjustmentUpdate extends Component {
    initialState = {
        showAdjustmentModal: false,
        quantity: 0,
        selectedBatch: {},
        showConfirmationModal: false,
        selectedReason: {},
    };

    state = this.initialState;

    componentDidMount = () => {
        const {
            match: {
                params: { type },
            },
            InventoryStore: {
                handleGetProductionBatches,
                handleSetItemType,
                handleGetSKUSalesAvailability,
            },
        } = this.props;

        const itemType = type === 'meals' ? 'Item' : type === 'addons' ? 'Addons' : 'Component';
        if (itemType === 'Component') {

        } else {
            handleGetSKUSalesAvailability(itemType);
            handleSetItemType(itemType);
            handleGetProductionBatches(false);
        }
    };

    handleUnselectSku = () => {
        const {
            InventoryStore: { handleSetCurrentSku },
        } = this.props;

        handleSetCurrentSku('');
        this.setState({ quantity: 0 });
    };

    handleShowAdjustmentModal = (selectedBatch) => {
        this.setState({ showAdjustmentModal: true, selectedBatch });
    };

    handleCloseAdjustmentModal = () => {
        this.setState(this.initialState);
    };

    handleShowConfirmationModal = () => {
        this.setState({ showConfirmationModal: true });
    };

    handleCloseConfirmationModal = () => {
        this.setState({ showConfirmationModal: false });
    };

    handleSetQuantity = (plusMinus) => {
        const { quantity } = this.state;
        if (plusMinus === 'plus') this.setState({ quantity: quantity + 1 });
        else this.setState({ quantity: quantity - 1 });
    };

    handleSetSKUReason = (reason) => {
        this.setState({selectedReason: reason});
    };

    handleCheckRequest = () => {
        const { quantity, selectedBatch } = this.state;

        if (selectedBatch.quantity_left + quantity < 0) {
            this.handleShowConfirmationModal();
        } else {
            this.handleSendRequest();
        }
    };

    handleSendRequest = () => {
        const { quantity, selectedReason, selectedBatch } = this.state;
        const {
            InventoryStore: { handleSetActualStock },
        } = this.props;

        let request = {
            id: selectedBatch.id,
            delta: quantity,
            menuId: selectedBatch.menu_item_id,
            production: false,
            menuItemSku: selectedBatch.sku,
            reason: selectedReason.value
        };

        handleSetActualStock(request, false, true);
        this.setState(this.initialState);
    };

    render() {
        const {
            showAdjustmentModal,
            quantity,
            selectedBatch,
            showConfirmationModal,
        } = this.state;
        const {
            InventoryStore: {
                loading,
                productionBatches,
                skusByLetter,
                currentSku,
                itemType,
                reasonsToShowInDropdown,
                selectedReason
            },
        } = this.props;

        if (loading) {
            return <Loading />;
        }

        if (!currentSku) {
            return (
                <Fragment>
                    <InventoryHeader title='Choose SKU to update' />
                    <div className='pm' style={{ maxWidth: '800px' }}>
                        <p className='fs--medium-large text--sofiapro text--left mbm'>
                            Select SKU
                        </p>
                        <div className={` `}>
                            {Object.keys(skusByLetter).map((letter) => (
                                <div
                                    key={letter}
                                    className={`${base}__skus_of_one_sku_letter`}
                                >
                                    <div
                                        className={`${base}__sku_letter_top_row`}
                                    >
                                        <h3 className='text--primary text--left fs--medium-large text--bolder'>{`Type ${letter}`}</h3>
                                        <h2 className='fs--medium-medium-large text--bolder width--150px'>
                                            Unsold Stock
                                        </h2>
                                        <h2 className='fs--medium-medium-large text--bolder width--100px'>
                                            To hold
                                        </h2>
                                        <div className="text--bold fs--small width--200px"></div>
                                    </div>

                                    {skusByLetter[letter].map((sku) => {
                                        return (
                                            <SKURowAdjustmentUpdate
                                                sku={sku}
                                                itemType={itemType}
                                                key={sku}
                                            />
                                        );
                                    })}
                                </div>
                            ))}
                        </div>
                    </div>
                </Fragment>
            );
        }

        const { batches } = productionBatches[currentSku];
        const startDate = (batch) =>
            moment(batch.arrival_at, 'x').format('DD/MM/YYYY');
        const endDate = (batch) =>
            moment(batch.expires_at, 'x')
                .subtract(1, 'days')
                .format('DD/MM/YYYY');

        return (
            <Fragment>
                <InventoryHeader
                    title={`${
                        itemType === 'Item'
                            ? currentSku.split('_')[0]
                            : currentSku
                    } Quick SKU Update`}
                    onClickBackLink={this.handleUnselectSku}
                />
                {batches
                    .slice()
                    .sort((a, b) => a.expires_at - b.expires_at)
                    .map((batch) => (
                        <Fragment key={batch.id}>
                            <div
                                className={`fs--medium-large ps bg--black text--white`}
                            >
                                {`${startDate(batch)} - ${endDate(batch)}`}
                            </div>
                            <div
                                className={`pl row col-lg-12`}
                            >
                                <div className='text--left col-lg-6 col-sm-12 col-xs-12'>
                                    <p className='text--bolder fs--medium-large'>
                                        {itemType === 'Item'
                                            ? currentSku.split('_')[0]
                                            : currentSku}
                                    </p>
                                    <p className='fs--medium-large'>{`${batch.quantity_left} Unsold Meals`}</p>
                                    <p className='fs--medium-large'>{`${batch.virtual_stock} Meals in the Hub`}</p>
                                </div>
                                <div className='col-lg-6 text--right col-sm-12 col-xs-12'>
                                    <div className='col-lg-6 col-sm-6 col-xs-6'>
                                        <button
                                            className='btn btn-primary mrm'
                                            onClick={() =>
                                                this.handleShowAdjustmentModal(batch)
                                            }
                                        >
                                            Update this batch
                                        </button>
                                    </div>
                                    <div className='col-lg-6 col-sm-6 col-xs-6' hidden={true}>
                                        <InventoryBatchHistoryModal
                                            batch={batch}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={`pl row col-lg-12`}>
                            <InventoryComponentStockUpdate  fromMealUpdateScreen ={true}/>
                            </div>
                        </Fragment>
                    ))}
                {showAdjustmentModal && (
                    <Modal>
                        <h3 className='text--boldest fs--medium-medium-large mtxs'>
                            {currentSku.split('_')[0]}
                        </h3>
                        <div className='mbm'>
                            <h2 className='fs--medium'>{`${startDate(
                                selectedBatch,
                            )} - ${endDate(selectedBatch)}`}</h2>
                            <div>
                                <span>Current Unsold Stock: </span>
                                <span className='text--bolder'>{`${selectedBatch.quantity_left} Meals`}</span>
                            </div>
                            <div>
                                <span>Items currently in Hub: </span>
                                <span className='text--bolder'>{`${
                                    selectedBatch.virtual_stock + quantity
                                } Meals`}</span>
                            </div>
                        </div>
                        <p className='mbm'>Add or take</p>
                        <div className='quantity-selector'>
                            <button
                                className={`quantity-selector-button`}
                                disabled={
                                    selectedBatch.virtual_stock + quantity === 0
                                }
                                onClick={() => this.handleSetQuantity('minus')}
                            >
                                <svg className='icon'>
                                    <use xlinkHref={`#icon-minus`} />
                                </svg>
                            </button>
                            <div className='fs--medium-large width--100px text--center'>{`${
                                quantity > 0 ? '+' : ''
                            }${quantity} units`}</div>
                            <button
                                className='quantity-selector-button'
                                onClick={() => this.handleSetQuantity('plus')}
                            >
                                <svg className='icon'>
                                    <use xlinkHref={`#icon-plus`} />
                                </svg>
                            </button>
                        </div>
                        <div className='row'>
                            <div className='col-xs-2'>Reason :</div>
                            <div className='col-xs-10'>
                                <Select
                                    className='fs--medium-medium-large width--50 center--horizontal'
                                    value={selectedReason}
                                    required
                                    options={reasonsToShowInDropdown}
                                    name='reasons'
                                    onChange={this.handleSetSKUReason}
                                    placeholder='Select Reason'
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                />
                            </div>
                        </div>
                        <div className='display--flex justify--space-around'>
                            <button
                                className='btn btn-secondary ms flex-1'
                                onClick={this.handleCloseAdjustmentModal}
                            >
                                Cancel
                            </button>
                            <button
                                className='btn btn-primary ms flex-1'
                                disabled={this.state.selectedReason.value == null}
                                onClick={() => this.handleCheckRequest()}
                            >
                                Confirm
                            </button>
                        </div>
                    </Modal>
                )}
                {showConfirmationModal && (
                    <Modal>
                        <div className='mbm fs--medium'>
                            You are removing stock that has already been sold.
                            CS will be notified to cancel the orders. This will
                            impact your HUB PERFORMANCE KPI. Are you sure?
                        </div>
                        <div className='display--flex justify--space-around'>
                            <button
                                className='btn btn-secondary ms flex-1'
                                onClick={this.handleCloseConfirmationModal}
                            >
                                Go Back
                            </button>
                            <button
                                className='btn btn-primary ms flex-1'
                                onClick={() => this.handleSendRequest()}
                            >
                                Proceed
                            </button>
                        </div>
                    </Modal>
                )}
            </Fragment>
        );
    }
}

export default withRouter(InventoryAdjustmentUpdate);
