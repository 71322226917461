import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import { inject, observer } from 'mobx-react';
import Webcam from 'react-webcam';
import Fullscreen from 'react-full-screen';
import BarcodeReader from 'react-barcode-reader';

import LiveDispatchHeader from './LiveDispatchHeader';
import Bag from './Bag';
import DineInOrders from './DineInOrders';
import Route from './Route';
import Loading from '../Loading';
import ScanningBox from '../ScanningBox';
import Modal from "../Modal";

const base = 'live-dispatch';
const audio = new Audio(`${process.env.PUBLIC_URL}/alert_store_closed.mp3`);

@inject(
    'DispatchStore',
    'LiveDispatchStore',
    'ProductionStore',
    'UserStore',
    'ScanningStore'
)
@observer
class LiveDispatch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFullscreen: false,
        };
        this.webcamRef = React.createRef();
    }

    componentDidMount() {
        const {
            LiveDispatchStore: { handleGetDispatches, handleGetDineInOrders, handleGetFoodPandaStoreStatus },
        } = this.props;
        handleGetDispatches(true);
        handleGetDineInOrders(true);
        this.interval = setInterval(() => {
            handleGetDispatches(false);
            handleGetDineInOrders(false);
        }, 1000 * 5); // 5 seconds

        handleGetFoodPandaStoreStatus()
        this.interval = setInterval(() => {
            handleGetFoodPandaStoreStatus();
        }, 1000  * 60); // 1 minute
    }

    handleSetFullscreen = () => {
        this.setState((prevState) => ({
            isFullscreen: !prevState.isFullscreen,
        }));
    };

    handleScanWrapper = (scannedBarcode) => {
        const { LiveDispatchStore, ScanningStore } = this.props;
        const { setItemImage, handledScannedItem } = LiveDispatchStore;
        const { handleScan } = ScanningStore;
        if (this.webcamRef.current) {
            const image = this.webcamRef.current.getScreenshot();
            setItemImage(image);
        }
        handleScan(scannedBarcode, handledScannedItem);
    };

    componentWillUnmount() {
        const {
            LiveDispatchStore: { resetProductionLastModified },
        } = this.props;

        clearInterval(this.interval);
        resetProductionLastModified();
    }

    handleError = (err) => {
        console.error(err);
    };

    getRouteId = (route) => {
        if (route.delivery_id) {
            return route.delivery_id;
        }
        if (route.dispatch_no && route.route_no) {
            return route.dispatch_no + '-' + route.route_no;
        }
        return route.start_date;
    }

    render() {
        const { isFullscreen } = this.state;
        const { LiveDispatchStore } = this.props;
        const {
            bags,
            routes,
            loading,
            itemImage: image,
            itemScanLoading,
            scannedItem,
            scannedItemSKU,
            scannedItemError,
            showScannedItemOnLiveDispatch,
            originalItemImageLink,
            recentlyClosedFoodPandaStores
        } = LiveDispatchStore;

        if (recentlyClosedFoodPandaStores.length > 0){
            audio.play()
        }

        if (loading) {
            return <Loading />;
        }

        const isOutletDineInEnabled = true;

        return (
            <Fullscreen
                enabled={isFullscreen}
                onChange={(isFullscreen) => this.setState({ isFullscreen })}
            >
                <Helmet>
                    <title>Live Dispatch</title>
                </Helmet>
                <LiveDispatchHeader goFullscreen={this.handleSetFullscreen} />
                <div className={`${base}`}>
                    <div className={`${base}__webcam-area`}>
                        {/* webcam picture quality, helpful issue for future ref: https://github.com/mozmorris/react-webcam/issues/195 */}
                        <Webcam
                            audio={false}
                            ref={this.webcamRef}
                            screenshotFormat="image/jpeg"
                            className="webcam"
                            screenshotQuality={1}
                            forceScreenshotSourceSize={true}
                            videoConstraints={{
                                height: 1080,
                                width: 1920,
                            }}
                        />
                    </div>

                    <div className={`display--flex`}>
                        <div className={`${base}__routes`}>
                            <div>
                                <h1
                                    className={`display--inline-block ${base}__heading`}
                                >
                                    Routes
                                </h1>
                                <span>{routes.length}</span>
                            </div>
                            <div className={`${base}__routes-container display--flex`}>
                                {routes.map((route) => (
                                    <Route route={route} key={this.getRouteId(route)} />
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className={`display--flex bg--white`} >                        
                        <div
                            className={`${base}__bags-container ${
                                isOutletDineInEnabled
                                    ? `bag-container-dine-enabled`
                                    : `bag-container-dine-not-enabled`
                            }`}
                        >
                            <div>
                                <h1
                                    className={`display--inline-block ${base}__heading`}
                                >
                                    Delivery Bags
                                </h1>
                                <span>{bags.length}</span>
                            </div>
                            <div className={`${base}__bags`}>                                
                                    {bags.map((bag) => (
                                        <div className="display--inline-block">
                                            <Bag bag={bag} key={bag.order_number} />
                                        </div>
                                    ))}
                            </div>
                        </div>
                        {isOutletDineInEnabled && (
                            <div
                                className={`${base}__dine-in-orders-container`}
                            >
                                <DineInOrders />
                            </div>
                        )}
                    </div>
                </div>
                <BarcodeReader
                    onError={this.handleError}
                    onScan={this.handleScanWrapper}
                />
                <ScanningBox
                    show={showScannedItemOnLiveDispatch}
                    scannedItem={scannedItem}
                    scannedItemSKU={scannedItemSKU}
                    scannedItemError={scannedItemError}
                    handleScan={this.handleScanWrapper}
                    loading={itemScanLoading}
                    originalItemImageLink={originalItemImageLink}
                    itemImage={image}
                />

                {recentlyClosedFoodPandaStores.length > 0 && (
                    <Modal width={'949px'}>
                        <div style={{textAlign: 'center'}}>
                            <p style={{fontSize: '72px', fontWeight: '700', color: '#FB275F'}}>
                                Alert - Store Closed
                            </p>
                            <br/><br/><br/>
                            <p style={{fontSize: '36px', fontWeight: '700'}}>
                                {recentlyClosedFoodPandaStores.map(p => (
                                    <span key={p}> {p} <br /></span>
                                    )
                                )}
                            </p>
                            <br/><br/><br/>
                            <p style={{fontSize: '30px', fontWeight: '400'}}>
                            This store is closed. Please re-open it immediately from the merchant app/device
                            </p>
                            <br/><br/>
                            <div className={`${base}__confirmation_btns`}>
                                <button
                                    className="btn btn-secondary ms flex-1"
                                    onClick={() => {LiveDispatchStore.recentlyClosedFoodPandaStores = []; audio.pause()}}
                                    style={{backgroundColor: 'black', width: '200px', height: '70px', fontSize:'24px'}}
                                >
                                    OK
                                </button>
                            </div>
                        </div>
                    </Modal>
                )}
            </Fullscreen>
        );
    }
}

export default LiveDispatch;
