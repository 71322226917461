import React, { Component, Fragment } from 'react';
import InventoryHeader from './InventoryHeader';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import moment from 'moment';

@inject('InventoryStore')
@observer
class Inventory extends Component {
    render() {
        return (
            <Fragment>
                <InventoryHeader title="Daily SKU Check" backLink="" />
                <div className="inventory-menu">
                    <h2>{moment().format('dddd - DD/MM/YYYY')}</h2>
                    <div className="btn-wrapper mm mtxl">
                        <Link to={`/inventory/daily-check/meals`}>
                            <button className="btn btn-primary width--300px">
                                Meals
                            </button>
                        </Link>
                    </div>
                    <div className="btn-wrapper mm">
                        <Link to={`/inventory/daily-check/addons`}>
                            <button className="btn btn-primary width--300px">
                                Addons
                            </button>
                        </Link>
                    </div>
                    <div className="btn-wrapper mm">
                        <Link to={`/inventory/daily-check/components`}>
                            <button className="btn btn-primary width--300px">
                                Components
                            </button>
                        </Link>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default Inventory;
