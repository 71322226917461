import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Fullscreen from 'react-full-screen';
import qs from 'qs';
import {Helmet} from "react-helmet";

import OrderCard from './OrderCard';
import Loading from '../Loading';
import QueueScreenHeader from './QueueScreenHeader';
import Modal from '../Modal';

const base = 'queue-screen';

@inject('QueueScreenStore')
@observer
class QueueScreen extends Component {
    state = {
        isFullscreen: false,
        showPreparedOrderModal: false,
        orderToShow: null,
    };

    componentDidMount() {
        this.handleSetFullscreen();
        const {
            QueueScreenStore: { handleGetQueuedOrders },
        } = this.props;
        const parameters = qs.parse(this.props.location.search.slice(1));
        const { token, hub: hubCode } = parameters;
        const ONE_SECOND = 1000 * 1;
        handleGetQueuedOrders({ token, hubCode });
        this.interval = setInterval(() => {
            handleGetQueuedOrders({ forceLatest: false, token, hubCode });
        }, 5 * ONE_SECOND); // 5 seconds

        this.modalInterval = setInterval(() => {
            const { showPreparedOrderModal, orderToShow } = this.state;
            const {
                QueueScreenStore: { orderShown, ordersToShow, ordersDone },
            } = this.props;

            //removing the showing order from ordersToShow and pushing it to Ready
            if (orderToShow && showPreparedOrderModal) {
                orderShown(orderToShow);
            }

            // if no ordersToShow, then set showPreparedOrderModal to false
            if (!ordersToShow || ordersToShow.length === 0) {
                this.setState({
                    showPreparedOrderModal: false,
                    ordersToShow: null,
                });
            }

            //show the order
            if (ordersToShow && ordersToShow.length > 0) {
                this.setState({
                    showPreparedOrderModal: true,
                    orderToShow: ordersToShow[0],
                });
                const audio = new Audio(
                    `${process.env.PUBLIC_URL}/order_prepared_bell.mp3`
                );
                audio.play();
            }
        }, 5 * ONE_SECOND);
    }

    handleSetFullscreen = () => {
        this.setState((prevState) => ({
            isFullscreen: !prevState.isFullscreen,
        }));
    };

    componentWillUnmount() {
        clearInterval(this.interval);
        clearInterval(this.modalInterval);
    }

    handleClose = () => {
        this.setState({ showPreparedOrderModal: false });
    };

    render() {
        const {
            isFullscreen,
            showPreparedOrderModal,
            orderToShow,
        } = this.state;
        const { QueueScreenStore } = this.props;
        const {
            loading,
            queuedOrders,
            ordersToShow,
            ordersDone,
        } = QueueScreenStore;
        if (loading) return <Loading />;
        return (
            <Fullscreen
                enabled={isFullscreen}
                onChange={(isFullscreen) => this.setState({ isFullscreen })}
            >
                <Helmet>
                    <title>Queue Screen</title>
                </Helmet>
                {!isFullscreen && (
                    <QueueScreenHeader
                        goFullscreen={this.handleSetFullscreen}
                    />
                )}
                <React.Fragment>
                    <div className={`${base} width--100`}>
                        <div
                            className={`${base}__queue-screen-main height--100 display--flex`}
                        >
                            <div
                                className={`${base}__preparing col-xs-12 col-lg-4`}
                            >
                                <h1
                                    className={`${base}__headline display--inline-block preparing-title`}
                                >
                                    Preparing
                                </h1>
                                <div className={`${base}__order-blocks`}>
                                    {queuedOrders &&
                                        queuedOrders
                                            .filter(
                                                (orders) =>
                                                    orders.status === 'Pending'
                                            )
                                            .map((order) => (
                                                <OrderCard
                                                    key={order.order_number}
                                                    order={order}
                                                />
                                            ))}
                                </div>
                            </div>
                            <div className={`${base}__partition`}>
                                <div className="popmeals-logo mtm">
                                    <p className="popmeals-logo-text">
                                        Welcome to
                                    </p>
                                    <img
                                        className="popmeals-logo-img"
                                        src={`${process.env.PUBLIC_URL}/pop_logo_black.png`}
                                        alt="popmeals logo"
                                    />
                                </div>
                            </div>
                            <div
                                className={`${base}__ready width--50 col-xs-12 col-lg-4`}
                            >
                                <svg
                                    className="svg-background"
                                    viewBox="0 0 975 1080"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <circle
                                        cx="951.5"
                                        cy="282.5"
                                        r="951.5"
                                        fill="#7056B5"
                                    />
                                </svg>
                                <div className={`${base}__ready-main`}>
                                    <h1
                                        className={`${base}__headline ready display--inline-block mrs mll`}
                                    >
                                        Ready
                                    </h1>
                                    <div className={`${base}__order-blocks`}>
                                        {ordersDone &&
                                            ordersDone
                                                .filter(
                                                    (orders) =>
                                                        orders.status ===
                                                        'Prepared'
                                                )
                                                .map((order) => (
                                                    <OrderCard
                                                        key={order.order_number}
                                                        order={order}
                                                    />
                                                ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {showPreparedOrderModal && (
                        <Modal
                            width="907px"
                            height="518px"
                            borderRadius="404px"
                            padding="0px"
                        >
                            <OrderCard showOnModal={true} order={orderToShow} />
                        </Modal>
                    )}
                </React.Fragment>
            </Fullscreen>
        );
    }
}

export default QueueScreen;
