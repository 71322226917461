import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

const base = 'secondary-header';

class InventoryHeader extends Component {
    handleGoBack = () => {
        const { history, onClickBackLink } = this.props;
        if (onClickBackLink) {
            onClickBackLink();
        } else {
            history.goBack();
        }
    };

    render() {
        const { title } = this.props;
        return (
            <div className={`${base}__wrapper bg--white`} style={{position:'sticky', top: 0}}>
                <div className={`${base}`}>
                    <div className="display--flex justify--center flex-align-items-center pll prl pts pbs">
                        <span className="fs--medium-medium-large">{title}</span>
                        <div
                            className={`${base}__left-icon`}
                            onClick={this.handleGoBack}
                        >
                            <svg className="icon">
                                <use xlinkHref={`#icon-chevron-thin-left`} />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(InventoryHeader);
