import { action, computed, observable } from 'mobx';
import * as api from '../services/api';
import InventoryConstants from '../components/inventory/InventoryConstants';
import UserStore from "./common/UserStore";
import InventoryStore from "./InventoryStore";


class ComponentStockStore {
    @observable unitSkus;
    @observable selectedUnitSku;
    @observable selectedStatus = { value: 'STOCKED', label: 'STOCKED' };
    @observable supplier;
    @observable batchId;
    @observable quantityPerPackaging = [];
    @observable unitSkusAndPackaging;
    @observable unitBatches = [];
    @observable unitBatchesHistory = [];
    @observable unitSkuList = {};
    @observable componentStockUpdateLoading = true;
    @observable inventoryOverview;
    @observable inventoryOverviewLoading = true;
    @observable allUnitSkus = [];
    @observable componentDailyStockLoading = true;

    getPackaging = (unitSku) => {
        let filteredSku = this.unitSkusAndPackaging.filter(u => u.id === unitSku.value || u.sku === unitSku.sku);
        if (filteredSku.length == 0) {
            return [];
        }

        return filteredSku[0].packaging.reduce(function(result, p){
            if (getPackagingLabel(p.specs) != null){
                result.push({ id: p.id, packaging: getPackagingLabel(p.specs), innerQuantity:  getInnerQuantity(p.specs)});
            }
            return result;
        }, []);

        function getInnerQuantity(specs){
            let quantity = 1;
            for (const spec of specs) {
                if (!spec.outer_unit_of_measurement) {
                    return;
                }
                if (spec.inner_quantity && spec.inner_unit_of_measurement) {
                    quantity *= spec.inner_quantity;
                }
            }
            return quantity;
        }

        function getPackagingLabel(specs) {
            let name = specs[0].outer_unit_of_measurement;
            let openBracketCount = 0;
            for (const spec of specs) {
                if (!spec.outer_unit_of_measurement) {
                    return;
                }
                if (spec.inner_quantity && spec.inner_unit_of_measurement) {
                    openBracketCount++;
                    name += '(' + spec.inner_quantity + ' ' + spec.inner_unit_of_measurement;
                }
            }
            for (let i = 0; i < openBracketCount; i++) {
                name = name + ')';
            }
            return name;
        }
    }

    @action setSelectedUnitSku = (unitSku) => {
        this.selectedUnitSku = unitSku;
        this.quantityPerPackaging = this.getPackaging(unitSku);
    };
    @action
    setSelectedStatus = (status) => {
        this.selectedStatus = status;
    };

    @action
    setSupplier = (e) => {
        const { value } = e.target;
        this.supplier = value;
    };

    @action
    setBatchId = (e) => {
        const { value } = e.target;
        this.batchId = value;
    };

    @action
    handleSaveComponentStock = (quantityPerPackaging) => {
        let request = {
            'unit_sku': this.selectedUnitSku.value,
            'supplier': this.supplier,
            'status': this.selectedStatus.value,
            'batch_id': this.batchId,
            'quantities': quantityPerPackaging
                .map(e => {
                    return { 'quantity': e.quantity, 'packaging_id': e.id };
                }),
        };
        return api.saveComponentStock(request);
    };

    @action
    handleGetUnitSkus = () => {
        api.getUnitSkus()
            .then(res => {
                this.unitSkusAndPackaging = res;
                this.unitSkus = this.unitSkusAndPackaging
                    .filter(u => u.packaging && u.packaging.length > 0)
                    .map(u => {
                        return { value: u.id, label: u.sku + ' : '+ u.name };
                    });
            })
            .catch((error) => console.log(error));
    };

    @action
    getAllUnitSkus = () => {
        api.getUnitSkus()
            .then(res => {
                this.allUnitSkus = res;
            })
            .catch((error) => console.log(error))
            .finally(() => this.componentStockUpdateLoading = false);
    };

    @action
    cleanUpForm = () => {
        this.selectedUnitSku = null;
        this.quantityPerPackaging = [];
        this.supplier = '';
        this.batchId = '';
        this.selectedStatus = { value: 'STOCKED', label: 'STOCKED' };
    };

    @action
    handleGetUnitBatches = () => {
        api.getUnitBatches()
            .then(res => {
                this.unitBatches = res;
                api.getUnitBatchesHistory()
                    .then(history => {
                        this.unitBatchesHistory = history;
                    })
            })
            .catch(error => console.log(error))
            .finally(() => this.componentStockUpdateLoading = false);
    };

    isStockCheckCompleted = (unitSku) => {
        const timestampFieldName = 'final_check_timestamp'
        return (
            unitSku[timestampFieldName] && InventoryStore.isUpdatedInTimeFrame(unitSku[timestampFieldName])
        );
    };

    buildSkuListForDailyStockCheck = (unitBatches, unitBatchesHistory) => {
        this.unitSkusList = {};
        for (const batch of unitBatches) {
            if (!this.unitSkusList[batch.unit_sku]) {
                this.unitSkusList[batch.unit_sku] = {
                    unit_sku: batch.unit_sku,
                    unsold: batch.quantity,
                    pre_order_quantity: batch.pre_order_quantity,
                    virtual_stock: batch.quantity + batch.pre_order_quantity,
                    initial_check_timestamp: batch.initial_check_timestamp,
                    final_check_timestamp: batch.final_check_timestamp,
                    name: batch.unit_name
                };
            } else {
                this.unitSkusList[batch.unit_sku] = {
                    unit_sku: batch.unit_sku,
                    unsold: this.unitSkusList[batch.unit_sku].unsold + batch.quantity,
                    pre_order_quantity: this.unitSkusList[batch.unit_sku].pre_order_quantity + batch.pre_order_quantity,
                    virtual_stock: this.unitSkusList[batch.unit_sku].virtual_stock + batch.quantity + batch.pre_order_quantity,
                    initial_check_timestamp: batch.initial_check_timestamp,
                    final_check_timestamp: batch.final_check_timestamp,
                    name: batch.unit_name
                };
            }
        }
        Object.keys(this.unitSkusList).forEach(sku => {
            const stock_check_tracker = unitBatchesHistory.filter((entry) => {
                return entry.unit_sku == sku && entry.reason === 'DAILY_STOCK_CHECK';
            });
            this.unitSkusList[sku].stock_check_tracker = stock_check_tracker;
            this.unitSkusList[sku].isFinalCheckCompleted = this.isStockCheckCompleted(this.unitSkusList[sku]);
        })
        this.unitSkuList = this.unitSkusList;
    }

    @action
    handleGetUnitBatchesForDailyStockCheck = () => {
        api.getUnitBatches()
            .then(batches => {
                api.getUnitBatchesHistory()
                    .then(history => {
                        this.buildSkuListForDailyStockCheck(batches, history);
                    })
                    .catch(error => console.log(error))
                    .finally(() => this.componentStockUpdateLoading = false);
            })
            .catch(error => console.log(error))
            .finally(() => this.componentStockUpdateLoading = false);
    };

    @action
    handleUpdateUnitBatch = (updateRequest) => {
        let request = {
            'unit_sku': updateRequest.unitSku,
            'quantity': updateRequest.quantity,
            'reason' : updateRequest.reason,
            'delta': updateRequest.delta
        };


        if (request.delta > 0 && (request.reason === "LSM_SAMPLING" || request.reason === "EXPIRED" || request.reason === "DAMAGED")) {
            UserStore.message = "INVALID OPERATION: CANNOT ADD STOCK WITH REASON " + request.reason;
            return;
        }

        this.componentStockUpdateLoading = true;
        return api.updateUnitBatch(request)
            .then(res => {
                this.handleGetUnitBatches();
            })
            .finally(() => this.componentStockUpdateLoading = false);
    };

    @action
    handleGetLiveInventoryOverview = () => {
        api.getLiveInventoryOverview()
            .then(res => {
                this.inventoryOverview = res;
            })
            .catch(error => console.log(error))
            .finally(() => this.inventoryOverviewLoading = false);
    };

    @action
    handleBulkUpdateUnitBatch = (updateRequests) => {
        this.componentStockUpdateLoading = true;
        let count = 0;
        let now = new Date();
        if (now.getHours() < InventoryConstants.STOCK_CHECK_START_HOUR && now.getHours() >= InventoryConstants.STOCK_CHECK_END_HOUR){
            alert("Stock Check can only be performed between 9 PM and 2 AM")
            this.componentStockUpdateLoading = false;
            return
        }
        for (const updateRequest of updateRequests) {
            let request = {
                'unit_sku': updateRequest.unitSku,
                'quantity': updateRequest.quantity,
                'reason': 'DAILY_STOCK_CHECK'
            };
            api.updateUnitBatch(request)
                .then(res => {
                    count++;
                    if (count == updateRequests.length) {
                        this.handleGetUnitBatchesForDailyStockCheck();
                    }
                }).catch((err) => {
                UserStore.message = err.message;
                this.componentStockUpdateLoading = false;
            })
        }
    };
}

const store = new ComponentStockStore();
export default store;
